import React from "react";
import styled from "styled-components";
import Button from "../Button";
import { useSelector } from "react-redux";

const HeaderPublic = (props) => {
  const token = useSelector((state) => state.accessStore.token);
  function changeRoute(route) {
    if (props.history.location.pathname !== "/") {
      props.history.push("/", {
        ancla: route,
      });
    } else if (props.history.location.pathname === "/") {
      const elmnt = document.getElementById(route);
      if (elmnt !== null) {
        elmnt.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      props.history.push("/");
    }
  }

  return (
    <div style={{zIndex: "123123"  }}>
      <div className="bg-white text-center" style={{width:"100%", padding:"0.8rem 2rem"}}>
        <Text1>¡Recicla y gana!  <span  style={{color: "#CDA23C", paddingLeft:"5px"}}> Únete a nuestra comunidad.</span></Text1>
      </div>
      <nav class="navbar navbar-expand-lg"  style={{backgroundColor: "rgb(7, 7, 7)",padding: "0.6rem 2rem"}}>
        <a class="navbar-brand" href="#">
            <Logo
              src="assets/images/Logo_negativo.svg"
              alt="zeroenergy"
              className="opacity"
              onClick={() => {
                changeRoute("home");
            }} />
        </a>
        <IconDiv className=" text-white navbar-toggle d-flex justify-content-center flex-column" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation" >
                <i
                  className="fa fa-bars"
                  data-toggle="tooltip"
                  // title="fa fa-flag"
                  style={{ fontSize: "20px" , opacity:"1 !important"}}
                ></i>
        </IconDiv>
        <div class="collapse navbar-collapse" id="navbarText" style={{background: "rgb(7, 7, 7)"}}>
          <ul class="navbar-nav mr-auto" id="ulMenu">
            <Items
              onClick={() => {
                changeRoute("aboutus");
              }}
            >
              Sobre nosotros
            </Items>
            <Items
              onClick={() => {
                changeRoute("sherpa");
              }}
            >
              Nuestro sistema
            </Items>
            <Items
              onClick={() => {
                changeRoute("Advantage");
              }}
            >
              Ventajas
            </Items>
            <Items
              onClick={() => {
                changeRoute("contact");
              }}
              >
              Contacto
            </Items>

          </ul>

          <ContainerItems >
            
              {token ? null : (
                <Button
                className="btn-login bg-transparent"
                small="120px"
                height="35px"
                marginRight="5%"
                font="15px"
                href="https://panel.zeroenergy.pro/#/login" /* TODO dont use hardcoded links..  */
                // onClick={() => props.history.push("/login")} // TODO: dont use hardcode links  dont use history.push.. use the ijection variables from mckaygerhard tutorial
                >
                  Iniciar Sesión
                </Button>
              )}
              {token ? null : (
                <Button
                outline  
                // link="/"
                small="120px"
                height="35px"
                font="15px"
                href="https://panel.zeroenergy.pro/#/register" // TODO dont use hardcoded links..   dont use history.push.. use the ijection variables from mckaygerhard tutorial
                  // onClick={() => props.history.push("/register")}
                  >
                  Registrate
                </Button>
              )}
              {token && (
                <Button
                link="/"
                small="120px"
                height="35px"
                onClick={() => props.history.push("/home")}
                >
                  Regresar
                </Button>
              )}
            </ContainerItems>
        </div>
      </nav>
    </div>
  );
};
const IconDiv = styled.div`
  @media (min-width:1000px){
    display:none !important;
  }
`
const Text1 = styled.div`
font-family: Roboto !important;


@media (max-width:400px){
  font-size: 15px;
}
@media (min-width:400px){
}
@media (min-width:1000px){
  width: 93vw;    
}

@media (min-width:1400px){
  width: 96vw;    
}
@media (min-width:1800px){
    font-size: large ;
    width: 98vw;    
  }

`;
const Logo = styled.img`
  width: auto;
  height: 17px;

  object-fit: contain;
  @media (max-width:400px){
    width: 106px;
  }
  @media (max-width:600px){
    width: 170px;
  }
  @media (max-width:978px){
    width: 130px;
  }

`;
const ContainerItems = styled.div`
    display: flex;
    justify-content: center;
    @media (min-width:1000px){
      width: 280px;
      padding-right: 8px;
    }
    @media (min-width:1800px){
      width: 18%;
    }
`;
const Items = styled.p`
  width: auto;
  height: 20px;
  margin: 0px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: white;
  @media (max-width: 1000px) {
    color: white;
    font-size: 20px;
    padding-bottom: 60px;
  }
  
  border-bottom: 0.5px solid transparent;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  padding: 0px 10px
  :hover {
    color: #3f768a;
    border-bottom: 0.5px solid #3f768a;
  }
  @media (min-width:1200px){
    padding: 0px 18px;
  }
  @media (min-width: 1300px) {
    // font-size: large;
    font-size: 15px;
  }
  @media (min-width: 2300px) {
    font-size: x-large;
  }
`;
export default HeaderPublic;
