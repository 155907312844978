export const LOGIN_INIT = "LOGIN_INIT";
export const LOGIN_COMPLETE = "LOGIN_COMPLETE";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const REGISTER_INIT = "REGISTER_INIT";
export const REGISTER_COMPLETE = "REGISTER_COMPLETE";
export const REGISTER_ERROR = "REGISTER_ERROR";

export const VERIFICATIONS_EMAIL_INIT = "VERIFICATIONS_EMAIL_INIT";
export const VERIFICATIONS_EMAIL_COMPLETE = "VERIFICATIONS_EMAIL_COMPLETE";
export const VERIFICATIONS_EMAIL_ERROR = "VERIFICATIONS_EMAIL_ERROR";

export const RECOVERY_PASSWORD_INIT = "RECOVERY_PASSWORD_INIT";
export const RECOVERY_PASSWORD_COMPLETE = "RECOVERY_PASSWORD_COMPLETE";
export const RECOVERY_PASSWORD_ERROR = "RECOVERY_PASSWORD_ERROR";

export const ACTIVE_ACCOUNT_INIT = "ACTIVE_ACCOUNT_INIT";
export const ACTIVE_ACCOUNT_COMPLETE = "ACTIVE_ACCOUNT_COMPLETE";
export const ACTIVE_ACCOUNT_ERROR = "ACTIVE_ACCOUNT_ERROR";

export const CHANGE_PASSWORD_INIT = "CHANGE_PASSWORD_INIT";
export const CHANGE_PASSWORD_COMPLETE = "CHANGE_PASSWORD_COMPLETE";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const GOOGLE_AUTHENTICADOR_QR_INIT = "GOOGLE_AUTHENTICADOR_QR_INIT";
export const GOOGLE_AUTHENTICADOR_QR_COMPLETE =
  "GOOGLE_AUTHENTICADOR_QR_COMPLETE";
export const GOOGLE_AUTHENTICADOR_QR_ERROR = "GOOGLE_AUTHENTICADOR_QR_ERROR";

export const ACTIVE_GOOGLE_AUTHENTICADOR_QR_INIT =
  "ACTIVE_GOOGLE_AUTHENTICADOR_QR_INIT";
export const ACTIVE_GOOGLE_AUTHENTICADOR_QR_COMPLETE =
  "ACTIVE_GOOGLE_AUTHENTICADOR_QR_COMPLETE";
export const ACTIVE_GOOGLE_AUTHENTICADOR_QR_ERROR =
  "ACTIVE_GOOGLE_AUTHENTICADOR_QR_ERROR";

export const ENTER_CODE_GOOGLE_AUTHENTICADOR_INIT =
  "ENTER_CODE_GOOGLE_AUTHENTICADOR_INIT";
export const ENTER_CODE_GOOGLE_AUTHENTICADOR_COMPLETE =
  "ENTER_CODE_GOOGLE_AUTHENTICADOR_COMPLETE";
export const ENTER_CODE_GOOGLE_AUTHENTICADOR_ERROR =
  "ENTER_CODE_GOOGLE_AUTHENTICADOR_ERROR";

export const LOGOUT = "LOGOUT";
