import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Botton = ({
  children,
  outline,
  small,
  width,
  type,
  link,
  onClick,
  uppercase,
  marginTop,
  marginBottom,
  marginRight,
  className,
  height,
  disabled,
  font,
  href,
  target
}) => {
  const btnLogin = className === "btn-login" ? className : "";
  return (
    <Button
      outline={outline}
      disabled={disabled}
      className={btnLogin}
      small={small}
      width={width}
      type={type}
      onClick={onClick}
      uppercase={uppercase}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
      height={height}
      font={font}
      href={href}
      target={target}
    >
      {link && link ? (
        <Link to={link && link}>{children}</Link>
      ) : (
        // <Link to={{pathname:'zero/#/login'}}>
          <a href={href} className={className}  target={target}>
              <p className={className }>{children}</p>

          </a>
        // </Link>
      )}

    </Button>
  );
};

const Button = styled.button`
  width: ${(props) => (props.width ? props.width + "!important" :  "100%")};
  max-width: ${(props) => (props.small ? props.small : "200px")};
  height: ${(props) => (props.height ? props.height : "40px")};
  outline: 0;
  cursor: pointer;
  border: none;
  text-transform: ${(props) => (props.uppercase ? "uppercase" : null)};
  border-radius: 5px;
  background: ${(props) =>
    props.outline
      ? props.outline
      : `#CDA23C
  `};
  white-space: nowrap;
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom};
  margin-right:${(props) => props.marginRight};
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  @media (max-width: 420px) {
    width: 100%;
    max-width: 95vw;
  }
  :hover {
    opacity: 0.8;
  }
  a {
    color: ${(props) => (props.outline ? "#BF7E00" : "#fff")};
    font-size: ${(props) => (props.font ? props.font : "16px")};
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-weight: 400;
  }
  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export default Botton;
