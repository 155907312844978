import * as React from "react";
const SweetAlert = () => {
  return (
    <div>
      <input
        type="button"
        id="click"
        style={{ display: "none" }}
      />
      <input
        type="text"
        id="title"
        style={{ display: "none" }}
      />
      <input
        type="text"
        id="message"
        style={{ display: "none" }}
      />
      <input
        type="text"
        id="type"
        style={{ display: "none" }}
      />
    </div>
  );
};

export default SweetAlert;
