import { put, call, takeLatest } from "redux-saga/effects";
import * as types from "./types";
// eslint-disable-next-line import/no-cycle
import API from "../../api";
import { setProfileInit } from "../profile/actions";

export function* loginCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/login", payload, {
      headers: { noAuth: true },
    });
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: types.LOGIN_COMPLETE,
        payload: result.data,
      });
      if (
        result.data &&
        result.data.user &&
        result.data.user.access2Fa === null
      ) {
        const success = { bool: true };
        yield put(setProfileInit(result.data.user));
        callback(success);
      } else {
        const success = { bool: true, showModal: true, body: payload };
        yield put(setProfileInit(result.data.user));
        callback(success);
      }
    } else {
      yield put({ type: types.LOGIN_ERROR });
    }
  } catch (error) {
    console.log(error)
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.info
    ) {
      callback({
        bool: false,
        message: error.response.data.info[0],
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.LOGIN_ERROR });
  }
}

export function* registerCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/users", payload, {
      headers: { noAuth: true },
    });
    if (result.status === 200 || result.status === 201) {
      callback({
        bool: true,
      });
      yield put({ type: types.REGISTER_COMPLETE });
    } else {
      yield put({ type: types.REGISTER_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message:
          typeof error.response.data.errors === "object"
            ? Object.values(error.response.data.errors)[0]
            : error.response.data.errors,
      });
    }
    yield put({ type: types.REGISTER_ERROR });
  }
}

export function* verificationsEmailCall({ payload, callback }) {
  try {
    const result = yield call(API.get, `/email-verifications/${payload}`, {
      headers: { noAuth: true },
    });
    if (result.status === 200 || result.status === 201) {
      callback({
        bool: true,
      });
      yield put({ type: types.VERIFICATIONS_EMAIL_COMPLETE });
    } else {
      yield put({ type: types.VERIFICATIONS_EMAIL_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message:
          typeof error.response.data.errors === "object"
            ? Object.values(error.response.data.errors)[0]
            : error.response.data.errors,
      });
    }
    yield put({ type: types.VERIFICATIONS_EMAIL_ERROR });
  }
}

export function* recoveryPasswordCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/recovery-passwords", payload, {
      headers: { noAuth: true },
    });
    if (result.status === 200 || result.status === 201) {
      callback({
        bool: true,
      });
      yield put({ type: types.RECOVERY_PASSWORD_COMPLETE });
    } else {
      yield put({ type: types.RECOVERY_PASSWORD_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.RECOVERY_PASSWORD_ERROR });
  }
}

export function* changePasswordCall({ payload, callback }) {
  try {
    const result = yield call(
      API.post,
      "/recovery-passwords-verifications",
      payload,
      {
        headers: { noAuth: true },
      }
    );
    if (result.status === 200 || result.status === 201) {
      callback({
        bool: true,
      });
      yield put({ type: types.CHANGE_PASSWORD_COMPLETE });
    } else {
      yield put({ type: types.CHANGE_PASSWORD_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.CHANGE_PASSWORD_ERROR });
  }
}

export function* activeAccountCall({ payload, callback }) {
  try {
    const result = yield call(API.post, `/account-verifications/${payload}`, {
      headers: { noAuth: true },
    });
    if (result.status === 200 || result.status === 201) {
      callback({
        bool: true,
      });
      yield put({ type: types.ACTIVE_ACCOUNT_COMPLETE });
    } else {
      yield put({ type: types.ACTIVE_ACCOUNT_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.ACTIVE_ACCOUNT_ERROR });
  }
}

export function* authenticatorGoogleCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/google-authenticator/qr", payload);
    if (result.status === 200 || result.status === 201) {
      callback({
        bool: true,
        qr: result.data,
      });
      yield put({ type: types.GOOGLE_AUTHENTICADOR_QR_COMPLETE });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message:
          error.response.data && error.response.data.errors
            ? Object.values(error.response.data.errors)[0]
            : "Por favor verifique su conexión a internet.",
      });
    }
    yield put({ type: types.GOOGLE_AUTHENTICADOR_QR_ERROR });
  }
}

export function* activeAuthenticatorGoogleCall({ payload, callback }) {
  try {
    const result = yield call(
      API.post,
      "/google-authenticator/qr/validate",
      payload
    );
    if (result.status === 200 || result.status === 201) {
      callback({
        bool: true,
      });
      yield put(setProfileInit(result.data.user));
      yield put({ type: types.ACTIVE_GOOGLE_AUTHENTICADOR_QR_COMPLETE });
    }
  } catch (error) {
    console.log(error);
    console.log(error.response);
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message:
          error.response.data && error.response.data.errors
            ? Object.values(error.response.data.errors)[0]
            : "Por favor verifique su conexión a internet.",
      });
    }
    yield put({ type: types.ACTIVE_GOOGLE_AUTHENTICADOR_QR_ERROR });
  }
}

export function* enterCodeAuthenticatorGoogleCall({ payload, callback }) {
  try {
    const result = yield call(API.post, "/login/google-authenticator", payload);
    if (result.status === 200 || result.status === 201) {
      yield put({ type: types.ENTER_CODE_GOOGLE_AUTHENTICADOR_COMPLETE });
      yield put(setProfileInit(result.data.user));
      yield put({
        type: types.LOGIN_COMPLETE,
        payload: result.data,
      });
      const success = { bool: true };
      callback(success);
    } else {
      yield put({ type: types.ENTER_CODE_GOOGLE_AUTHENTICADOR_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message:
          error.response.data && error.response.data.errors
            ? Object.values(error.response.data.errors)[0]
            : "Por favor verifique su conexión a internet.",
      });
    }
    yield put({ type: types.ENTER_CODE_GOOGLE_AUTHENTICADOR_ERROR });
  }
}

export default function* access() {
  yield takeLatest(types.LOGIN_INIT, loginCall);
  yield takeLatest(types.REGISTER_INIT, registerCall);
  yield takeLatest(types.VERIFICATIONS_EMAIL_INIT, verificationsEmailCall);
  yield takeLatest(types.RECOVERY_PASSWORD_INIT, recoveryPasswordCall);
  yield takeLatest(types.CHANGE_PASSWORD_INIT, changePasswordCall);
  yield takeLatest(types.ACTIVE_ACCOUNT_INIT, activeAccountCall);
  yield takeLatest(types.GOOGLE_AUTHENTICADOR_QR_INIT, authenticatorGoogleCall);
  yield takeLatest(
    types.ACTIVE_GOOGLE_AUTHENTICADOR_QR_INIT,
    activeAuthenticatorGoogleCall
  );
  yield takeLatest(
    types.ENTER_CODE_GOOGLE_AUTHENTICADOR_INIT,
    enterCodeAuthenticatorGoogleCall
  );
}
