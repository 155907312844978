import React, { useRef,useState } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import Input from "../components/Input";
import { useSelector, useDispatch } from "react-redux";
import Collapse from "../components/Collapse";
import { postContact1Init } from "../ducks/general/actions";
import * as Yup from "yup";
import styled,{keyframes} from "styled-components";
import HeaderPublic from "../components/layout/HeaderPublic";
import FooterPublic from '../components/layout/FooterPublic'
import Button from "../components/Button";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { APP_URL } from "../constants";

const TestPage = (props) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const slider = useRef();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.generalStore.isLoggingInContact
  );
  const handleClickSend = (values, { resetForm }) => {
    const body = {
      name: values.name,
      lastname:values.lastname,
      email: values.email,
      message: values.message,
    };
    dispatch(
      postContact1Init(body, (Response) => {
        if (Response.bool) {
          resetForm();
          document.getElementById("title").value =
            "Gracias por comunicarte con nosotros";
          document.getElementById("message").value =
            "Su mensaje fue enviado exitosamente.";
          document.getElementById("type").value = "success";
          document.getElementById("click").click();
        } else {
          document.getElementById("title").value = Response.message;
          document.getElementById("message").value = "";
          document.getElementById("type").value = "error";
          document.getElementById("click").click();
        }
      })
    );
  };
  const settings = {
    className: "card-group",
    dots: true,
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
        slidesToShow: 2,
        }
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 500,
        settings: {
        slidesToShow: 1,
        centerPadding: '0px',
        }
      },
    ]
  };
  const next = () => {
    slider.current.slickNext();
  };
  const previous = () => {
    slider.current.slickPrev();
  };
  return (
    <div style={{fontFamily:"'Roboto'"}}>
            <HeaderPublic history={props.history} />
            <ContainImage>
                <ContainSection>
                    <ContainSectionImage1>
                        <ImgMain1 src="/assets/images/backgroundEdit2.png" alt="" /> 
                    </ContainSectionImage1>
                    <SectionContainBg>

                      <ContantTextBg>
                        <H4Section className="mb-2 txt-h4">La Plataforma de Tokenización que da </H4Section>
                        <h1 className="mb-0 txt-h1 font-sensation" >
                          Sentido Ecológico a la Tecnología Blockchain
                        </h1>
                        <H5Section className="mt-2 txt-h5">
                        "Convierte la basura en Cryptoactivos: una nueva era para la Tokenización de los residuos con Zero-Energy"
                        </H5Section>
                      </ContantTextBg>
                    
                    </SectionContainBg>
                    <ContainSectionImage2>
                        <ImgMain2 src="/assets/images/backgroundEdit1.png"  alt=""/>
                    </ContainSectionImage2>
                </ContainSection>
            </ContainImage>
            <ContainImage2>
                <ContainImg2Main>
                        <Img1Main src="/assets/images/e_recortada.png" alt="" />
                </ContainImg2Main>
                <ContainSection1  id="aboutus">
                    <section>
                        <h1 className="txt-h1">Tokenizamos basura con Blockchain</h1>
                        <p className="txt-h4 mb-4">Transforma los desechos en Cryptoactivos.</p>
                        <Button
                            className="btn-lg p-0 text-white"
                            width="200px"
                            small="200px"
                            height="45px"
                            font="18px"
                            href="https://panel.zeroenergy.pro/#/register" /* TODO dont use hardcoded links.. peding use variables.. from mckaygerhard*/
                        >
                            Únete al cambio
                        </Button>
                    </section>
                </ContainSection1>
                <ContainImg2Main style={{marginLeft:"auto"}}>
                        <Img2Main src="/assets/images/+_recortada.png" alt="" />
                </ContainImg2Main>
                <DivContainWhite> </DivContainWhite>
            </ContainImage2>
            <br />
            <br />
            <br />

            <AboutThePlatform className="pr-5 pl-5">
                <section>
                  <div className="color-letters-special mb-4" style={{fontSize: "x-large"}}>Sobre nosotros</div>
                  <h3 className="txt-h1">Acerca de la plataforma</h3>
                </section>
                <br />
                <br />

                <DivInformationPlatform>
                  <SectionInformationPlatformText1>
                    <h1 className="txt-h1 font-sensation mb-0" style={{fontStyle:"italic"}}><span style={{color: "#CDA23C", fontSize: "xxx-large"}}> + </span> INNOVATION</h1>
                      <TextSectionInformationPlatafform className="txt-p-1">
                      Somos una compañía con una visión clara: ser líderes en la tokenización de la basura. “La
                      materia prima más abundante en el planeta”.
                      </TextSectionInformationPlatafform>
                  </SectionInformationPlatformText1>
                  <SectionInformationPlatformText2>
                    <h1 className=" txt-h1 font-sensation mb-0" style={{fontStyle:"italic"}}><span style={{color: "#CDA23C", fontSize: "xxx-large"}}> + </span> EFFICIENT</h1>
                    <TextSectionInformationPlatafform className="txt-p-1">
                    A través de nuestras alianzas estratégicas y nuestra presencia global, tenemos la capacidad de
                    impactar tanto a los inversores como al medio ambiente, generando beneficios sostenibles
                    para el mundo.
                    </TextSectionInformationPlatafform>
                  </SectionInformationPlatformText2>
                </DivInformationPlatform>
                <br /><br />
                <DivImgInformationPlatform  id="sherpa">
                </DivImgInformationPlatform>
            </AboutThePlatform>
            <br /><br /><br /><br /><br /><br /><br /><br />
            <OurSystem className=" pl-5">
              <SectionInformationOurSystem>
                  <h5 className="color-letters-special">Nuestro sistema</h5>
                  <H1Titles className="txt-h1 txt-h2 font-sensation" style={{fontWeight: "bold", marginBottom:"0.66rem"}}>UNA SOLUCIÓN EMERGENTE</H1Titles>
                  {/* <TextGeneral className="txt-p-1">Un modelo de inversión con riesgo ZERO</TextGeneral> */}
              </SectionInformationOurSystem>
              <br /><br /><br /><br />
              <DivInformationOurSystem>
                <SectionInformationTokenZero>
                  <TextInformationToken className="pb-4" >
                    <h5 className="txt-h4 font-sensation" style={{ fontStyle:"italic",fontWeight: "bold"}}><span style={{  fontSize: "xx-large",fontWeight:"bold"}}>-</span> Emissions</h5>
                    <TextGeneral className="txt-p-1" style={{ fontFamily:"'Roboto'"}} >
                      Nuestra visión, es crear una solución que conecta la descontaminación con una oportunidad
                      financiera.
                    </TextGeneral>
                  </TextInformationToken>
                  <TextInformationToken className="pb-5">
                    <h5 className="txt-h4 font-sensation" style={{ fontStyle:"italic",fontWeight: "bold"}}><span style={{  fontSize: "xx-large",fontWeight:"bold"}}>+</span> Energy</h5>
                    <TextGeneral className="txt-p-1" >
                      Mediante la Tokenización de los residuos, contribuimos de manera significativa a la
                      sostenibilidad global. Buscando fomentar el uso eficiente de recursos, reducir la
                      contaminación y aportar soluciones innovadoras, que ayuden a combatir los problemas
                      ambientales actuales generando una economía verde.
                    </TextGeneral>
                  </TextInformationToken>
                </SectionInformationTokenZero>
                <br />
                <SectionInformationPlatformText2>
                    <ImgDesktopInformationPlataform
                      src="assets/images/changed.png" alt=""
                    >
                    </ImgDesktopInformationPlataform>
                </SectionInformationPlatformText2>
              </DivInformationOurSystem>
            </OurSystem>
                <br />
                <br />
                <br />
                <br />

              <ZeroRisk className="text-center">
                  <span className="color-letters-special txt-p-1"
                  style={{
                    borderRadius: "8px",
                    padding: "5px",
                    backgroundColor: "#FFF8E5"
                  }}>Confianza</span>
                  <H1Titles className="txt-h1" style={{fontWeight: "bold",marginTop:"6px"}}>
                    Riesgo ZERO
                  </H1Titles>
                  <TextGeneral className="txt-p-1">
                    Lo disruptivo e innovador de este modelo es que siempre se podrá generar activos, ya que,
                    existe la posibilidad de vender los derivados energéticos producidos por el reciclado de la
                    basura, generando beneficios a través de acuerdos gubernamentales fijando un precio por la
                    venta de energía (PPA).
                  </TextGeneral>
                  <br />
              </ZeroRisk>
                  <ContVideo>
                    <video width="100%" height="100%" autoPlay={true} controls={true}   controlsList="nofullscreen" muted loop>
                      <source src="https://cnd-zero-energy.s3.eu-west-1.amazonaws.com/VIDEO1.mp4" type="video/mp4"/> 
                    </video>
                  </ContVideo>
                  <div style={{
                      height: "10px",
                      background: "linear-gradient(351.97deg, #C4A861 -32.38%, #906126 23.06%, #D5BF74 69.28%, #A78040 113.31%)",
                      width: "100%"
                  }} className="line">
                  </div>
              <Emissions className="pr-5 pl-5 " >
                  <SectionInformationEmissions>
                    <div>
                      <h4  style={{color:'white'}}><span style={{color: "white", fontSize: "xxx-large",fontWeight:"bold"}}>-</span> Emissions</h4>
                      <h2 style={{color:'white'}}><span style={{color: "white", fontSize: "xxx-large",fontWeight:"bold"}}>+</span> Energy</h2>
                      <span style={{color:'#9E9E9E', fontFamily: 'Inter', fontStyle: "normal",fontWeight: "400"}}> Criptoactivos ecológicos </span>
                      <ContainButtonsEmission>
                        <Button
                          
                          marginTop="10px"
                          marginRight="10px"
                          small="100%"
                          marginBottom="10px"
                          height="60px"
                          outline
                          href="https://drive.google.com/drive/folders/1GTscjxu7zNZGAWdngiq0ZO3KVkg6cHB8?usp=drive_link"
                          target="_blank"
                          >
                          <span style={{fontSize:"23px"}}>
                            Descargas
                          </span>
                        </Button>
                        <Button
                        small="100%"
                        marginTop="10px"
                        marginBottom="10px"
                        height="60px"
                        href="https://panel.zeroenergy.pro/#/register" /* TODO dont use hardcoded links.. peding use variables.. from mckaygerhard*/
                        >
                          {/* onClick={() => props.history.push("/login")} */}
                          <span style={{fontSize:"23px"}}>
                            Regístrate
                          </span>
                        </Button>
                      </ContainButtonsEmission>
                    </div>
                  </SectionInformationEmissions>
                  <SectionContainImagesEmission>
                    <Images
                    style={{paddingTop:'10px'}}
                    >
                      <Image1 src="assets/images/64639ea0e03d9fec5d9ed18bb58fa395.png" alt="" />
                      <img src="assets/images/7eeebbc52dd510eb64e5f5425daab794.png" alt="" />
                      <Image2 src="assets/images/1ecd0754d9d9e80e6029f9011a4c38d3.png" alt=""/>
                    </Images>
                  </SectionContainImagesEmission>
              </Emissions>
              <TokenZero>
                <ContainTokenZeroImg>
                  <ContainBackgroundColorToken>
                    <SectionInformationOurSystemText1>
                      <div className="pb-4 ml-3" >
                        <H1Titles className="txt-h1 font-sensation" style={{fontWeight: "bold"}}>TOKEN ZERO</H1Titles>
                        <TextGeneral id="Advantage">
                          ¡Únete a la revolución que está cambiando el mundo de la Tokenización! Accede a la reserva
                          del Token ZERO en fase de preventa solo para los miembros de la comunidad ZERO
                        </TextGeneral>
                      </div>
                    </SectionInformationOurSystemText1>
                    <TokenLogoZeroEnergy >
                        <img src="assets/images/zeroEnergy-token.png" alt="" width="300px"/>
                    </TokenLogoZeroEnergy>
                  </ContainBackgroundColorToken>
                </ContainTokenZeroImg>
              </TokenZero>
              <Advantages>
                <div >
                  <AdvantagesText >
                    <H1Titles className="text-light font-sensation" style={{marginRight: "10px",  fontSize: "xxx-large"}}>Ventajas</H1Titles>
                    <h5 style={{
                          backgroundColor: "white",
                          height: "max-content",
                          width:"max-content",
                          padding: "3px 5px 3px 5px",
                          borderRadius: "12px",
                    }}>

                    <a href="" style={{
                      color: "#0065C1"
                    }}>
                      <TextGeneral>
                      ¿Qué ganas al ser parte?
                      </TextGeneral>
                    </a>
                    </h5>
                  </AdvantagesText>
                  <Slider ref={(c) => (slider.current = c)}  {...settings}>
                    <Card className="card">
                      <div className="card-header " style={{ paddingBottom: "0.8rem"}} >
                        <h1 className="d-flex">
                        <img src="assets/images/Vector.png" alt="" style={{marginRight:"4px"}}/>
                          {/* Layers */}
                        </h1>
                      </div>
                      <br /><br /><br />
                      <SectionCardSlides>
                        <div className="card-body" >
                          <h5 className="card-title"   style={{fontFamily:"'Roboto'"}}>Tokens ZERO asegurados</h5>
                          <p className="card-text txt-p-1">Participa en la oportunidad de obtener + del 1000% de rentabilidad sobre tu inversión.</p>
                        </div>
                      </SectionCardSlides>
                    </Card>
                    <Card className="card" >
                      <div className="card-header" style={{ paddingBottom: "0.8rem"}}>
                        <h1 className="d-flex"  style={{fontFamily:"'Roboto'"}}>
                        <img src="assets/images/Vector2.png" alt="" style={{marginRight:"4px"}} />
                          {/* Sisyphus */}
                        </h1>
                      </div>
                      <br /><br /><br />
                      <SectionCardSlides>
                        <div className="card-body">
                          <h5 className="card-title"   style={{fontFamily:"'Roboto'"}}>Tokenización Económica</h5>
                          <p className="card-text txt-p-1">Súmate a la economía de tokenización de la materia prima más abundante en el planeta.</p>
                        </div>
                      </SectionCardSlides>
                    </Card>
                    <Card className="card">
                      <div className="card-header">
                        <h1 className="d-flex">
                        <img src="assets/images/Vector3.png" alt="" style={{marginRight:"4px" }}/>
                          {/* Circooles                           */}
                        </h1>
                      </div>
                      <br /><br /><br />
                      <SectionCardSlides>
                        <div className="card-body">
                          <h5 className="card-title"   style={{fontFamily:"'Roboto'"}}>Reciclamos mientras ganamos</h5>
                          <p className="card-text txt-p-1">Recicla y recibe beneficios mientras protegemos el medio ambiente.</p>
                        </div>
                      </SectionCardSlides>
                    </Card>
                    <Card className="card">
                      <div className="card-header">
                        <h1 className="d-flex">
                        <img src="assets/images/Vector4.png" alt="" style={{marginRight:"4px" }}/>

                          {/* Featured */}
                        </h1>
                      </div>
                      <br /><br /><br />
                      <SectionCardSlides>
                        <div className="card-body">
                          <h5 className="card-title"   style={{fontFamily:"'Roboto'"}}>Pre-Venta ZERO</h5>
                          <p className="card-text txt-p-1">Regístrate en nuestra plataforma y adquiere el token en la fase de preventa.</p>
                        </div>
                      </SectionCardSlides>
                    </Card>
                  </Slider>
                  <div className="contain d-flex justify-content-around"  style={{width:"135px", marginTop:"20px"}}>
                  <ButtonCustomArrows onClick={previous}> <span>{"<"}</span> </ButtonCustomArrows>
                  <ButtonCustomArrows onClick={next}> <span>{">"}</span> </ButtonCustomArrows>
                  </div>
                </div>
              </Advantages>
              <br />
              <br />
              <br />
              <br />

                <Divparticipate>
                <h5 className="color-letters-special" style={{fontSize: "x-large"}}>Comienzo</h5>
                <H1Titles className="txt-h1" style={{fontFamily: 'Sansation', fontStyle: "normal"}}>Como puedo participar</H1Titles>
                <TextGeneral className="txt-p-1" style={{fontFamily: "'Roboto' !important", fontStyle: "normal"}}>Tan solo 3 pasos para comenzar a generar beneficios</TextGeneral>
                <br />
                <br />
                <ContainCards className="">
                  <CardParticipate className="card">
                    <div className="d-flex justify-content-center">
                    <img src="assets/images/home-icon3.png" alt="" style={{maxWidth:'70%'}}/>
                    </div>
                    <br />  <br />
                    <div className="card-body" style={{background: "#FAFAFA"}}>
                    <h5 className="card-title txt-h4">Crea una <span className="color-letters-special"> cuenta</span></h5>
                      <TextGeneral className="card-text txt-p-1">Es totalmente gratuita, solo tienes que rellenar el formulario de registro y validar el email de confirmación.</TextGeneral>
                    </div>
                  </CardParticipate>
                  <CardParticipate className="card">
                    <div className="d-flex justify-content-center">
                    < img src="assets/images/home-icon1.png" alt="" style={{maxWidth:'70%'}}/>
                    </div>
                    <br />
                    <br />
                    <div className="card-body"  style={{background: "#FAFAFA"}}>
                    <h5 className="card-title txt-h4">Selecciona un <span className="color-letters-special">importe</span></h5>
                      <TextGeneral className="card-text txt-p-1">Selecciona el importe que deseas, desde tan solo 100 USDT puedes participar en la preventa del token ZERO.</TextGeneral>
                    </div>
                  </CardParticipate>
                  <CardParticipate className="card">
                    <div className="d-flex justify-content-center">
                      <img src="assets/images/home-icon2.png" alt="" style={{maxWidth:'74%'}}/>
                    </div>
                    <br />
                    <div className="card-body"  style={{background: "#FAFAFA"}}>
                    <h5 className="card-title txt-h4">Obtén <span className="color-letters-special">Beneficios</span></h5>
                      <TextGeneral className="card-text txt-p-1">Comparte nuestra visión con más personas y gana por cada persona que sumas al proyecto.</TextGeneral>
                    </div>
                  </CardParticipate>
                </ContainCards>
              </Divparticipate>
              <Roadmap>
                <ContainRoadmapImage className="g-col-6">
                    <h3>Roadmap</h3>
                    <H1Titles style={{fontFamily: 'Sansation', fontStyle: "normal",fontWeight: "700", fontSize:"72px"}}>Zero-Energy</H1Titles>
                    <h4 style={{color:"#475467"}}> Un proyecto generando energía limpia</h4>
                    <br />
                    <div style={{    height: "100%", width: "62%"}}>
                      <RoadMapImage
                        src="assets/images/main-platform-trash2.png" alt="" style={{ height: "800px"}}
                      >
                      </RoadMapImage>
                    </div>
                </ContainRoadmapImage>
                <ContainInformationRoadMap  className="g-col-6">
                  <div id="accordion">
                    <div className="card" style={{marginBottom: "0",backgroundColor:"#FAFAFA"}} >
                      <CardHeader className="card-header" id="headingOne">
                        <h5 className="mb-0">
                          <button className="btn btn-link d-flex" style={{textDecoration:"none"}} data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                            <H1Titles className="txt-h1 font-sensation" >Año <span className="color-letters-special"> 2019</span></H1Titles>
                          </button>
                        </h5>
                        <TextGeneral className="txt-p-1"  style={{width: "80%"}}>Se realizan los primeros contactos y pre-acuerdos. Se intercambian varias LOI (cartas de intenciones) donde se propone la explotación de los residuos en Marruecos.</TextGeneral>
                      </CardHeader>
                    </div>
                    <br />

                    <div className="card" style={{marginBottom: "0",backgroundColor:"#FAFAFA"}}>
                      <CardHeader className="card-header" id="headingTwo">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed" style={{textDecoration:"none"}} data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <H1Titles className="txt-h1 font-sensation" > Año <span  className="color-letters-special"> 2020</span> </H1Titles>
                          </button>
                        </h5>
                        <TextGeneral className="txt-p-1"  style={{width: "80%"}}>En este momento, los miembros del corporativo Zero-Energy, experimentan y desarrollan plataformas tecnológicas para terceros, ampliando su know how que será clave para el futuro.</TextGeneral>
                      </CardHeader>
                      {/* <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body text-left">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div> */}
                    </div>
                    <br />
                    <div className="card" style={{marginBottom: "0",backgroundColor:"#FAFAFA"}}>
                      <CardHeader className="card-header" id="headingThree">
                        <h5 className="mb-0">
                          <button className="btn btn-link collapsed " style={{textDecoration:"none"}} data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                          <H1Titles className="txt-h1 font-sensation" >Año <span  className="color-letters-special"> 2021 </span></H1Titles>
                          </button>
                        </h5>
                        <TextGeneral className="txt-p-1" style={{width: "80%"}}>Se comienza a investigar otros sistemas tecnológicos y se presta mayor atención a los negocios que incorporan el Bitcoin y la tecnología Blockchain en sus plataformas.</TextGeneral>
                      </CardHeader>
                      {/* <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body text-left">
                          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                        </div>
                      </div> */}
                    </div>
                    <br />
                    <div className="card" style={{marginBottom: "0", backgroundColor:"#FAFAFA"}}>
                      <CardHeader className="card-header " id="headingFouter"  style={{backgroundColor:"#FAFAFA"}} onClick={() => setOpen2(!open2)}>
                          <h5 className="mb-0 w-100">
                            <button className="btn btn-link collapsed w-100 p-0" style={{textDecoration:"none"}} data-toggle="collapse" data-target="#collapseFour"  aria-controls="collapseFour">
                              <LiOftenQuestions className="list-group-item d-flex justify-content-between align-items-center border-0" style={{backgroundColor:"#FAFAFA"}}>
                              <H1Titles className="txt-h1 font-sensation">  Año <span  className="color-letters-special"> 2022</span> </H1Titles>
                              {
                                open2
                                ? 
                                  <img src="/assets/images/Iconwrapmenos.png" alt="logo zeroenergy" style={{width:"40px"}} />
                                :          
                                  <img src="/assets/images/IconMas.png" alt="logo zeroenergy" style={{width:"37px"}} />
                              }
                              </LiOftenQuestions>
                            </button>
                          </h5>
                        </CardHeader>
                      { open2 &&
                        <div id="collapseFour" className="collapse" aria-labelledby="headingFouter" data-parent="#accordion">
                        <div className="card-body text-left">
                          <TextGeneral className="font-sensation txt-p-1"><strong>Enero </strong> </TextGeneral>                            
                          <br />
                          <TextGeneral className="txt-p-1">Siguiendo los patrones que conducen a la obtención de altos rendimientos en los diferentes
                            proyectos en estudio, se descubre la operativa para tokenizar la basura y aprovechar los
                            residuos como fuente de energía.
                          </TextGeneral>
                          <br /><br />
                          <TextGeneral className="font-sensation txt-p-1"><strong>Mayo</strong></TextGeneral>
                          <br />
                          <TextGeneral className="txt-p-1">Se inscribe la sociedad ZERO ENERGY RECICLED OUT.LLC con la que se operan los acuerdos.</TextGeneral>
                          <br /><br />
                          <TextGeneral className="font-sensation txt-p-1"><strong>Septiembre</strong></TextGeneral>
                          <br />
                          <TextGeneral className="txt-p-1">Se realizan los trabajos de diseño y marquetería y se crea la imagen corporativa.</TextGeneral>
                          <br /><br />
                          <TextGeneral className="font-sensation txt-p-1"><strong>Octubre</strong></TextGeneral>
                          <br />
                          <TextGeneral className="txt-p-1">Empezamos el desarrollo de la plataforma de administración, la cual permita a todas las personas aprovecharse y sacar beneficio de nuestro sistema.</TextGeneral>
                          <br /><br />
                          <TextGeneral className="font-sensation txt-p-1"><strong>Diciembre</strong></TextGeneral>
                          <br />
                          <TextGeneral className="txt-p-1">Se logra integrar nuestra plataforma con la tecnología Blockchain. Realizamos las primeras pruebas de depósitos y retiros automáticos.</TextGeneral>
                          <br />
                        </div>
                      </div>
                    }
                    </div>
                    <br />
                    <div className="card" style={{marginBottom: "0",backgroundColor:"#FAFAFA"}}>
                      <CardHeader className="card-header" id="headingFive" onClick={() => setOpen(!open)}>
                        <h5 className="mb-0 w-100">
                          <button className="btn btn-link collapsed w-100 p-0" style={{textDecoration:"none"}} data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                            <LiOftenQuestions className="list-group-item d-flex justify-content-between align-items-center border-0" style={{backgroundColor:"#FAFAFA"}}>
                            <H1Titles className="txt-h1 font-sensation mt-auto mb-auto" > Año <span  className="color-letters-special"> 2023</span> </H1Titles>
                              {
                                open
                                ? 
                                  <img src="/assets/images/Iconwrapmenos.png" alt="logo zeroenergy" style={{width:"40px"}} />
                                :          
                                <img src="/assets/images/IconMas.png" alt="logo zeroenergy" style={{width:"37px"}} />
                              }
                            </LiOftenQuestions>
                          </button>
                        </h5>
                    </CardHeader>
                      { open &&

                        <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                        <div className="card-body text-left">
                          <TextGeneral className="font-sensation txt-p-1"><strong>Enero</strong></TextGeneral>
                          <br />
                          <TextGeneral className="txt-p-1"> Se concretan los acuerdos para la explotación de las basuras y residuos de Marruecos y se consiguen grandes aportaciones para el proyecto, como Licencias y Terrenos.</TextGeneral>
                          <br /><br />
                          <TextGeneral className="font-sensation txt-p-1"><strong>Febrero</strong></TextGeneral>
                          <br />
                          <TextGeneral className="txt-p-1">Pre-lanzamientos Co-fundadores primera ronda de financiación.</TextGeneral>
                          <br /><br />
                          <TextGeneral className="font-sensation txt-p-1"><strong>Junio</strong></TextGeneral>
                          <br />
                          <TextGeneral className="txt-p-1">Lanzamiento del sistema Zero-Energy para todo el mundo</TextGeneral>
                          <br /><br />
                        </div>
                      </div>
                      }

                    </div>
                  </div>
                </ContainInformationRoadMap>
              </Roadmap>
              <br />
              <br />
              <OurGlobalCommunity>
              <br />
              <br />
                <div className="text-center ">
                <h4 className="color-letters-special txt-h4">
                  Crecimiento exponencial
                </h4>
                <H1Titles className="text-light txt-h1">
                  ¡Nuestra comunidad global no para de crecer!
                </H1Titles>
                <h4 style={{color: "#9E9E9E"}}>
                  ¡Únete a nuestra comunidad y no te pierdas ninguna novedad!                
                </h4>
                <br />
                <br />
                  <ImgOurGlobalCommunity
                  src="assets/images/bg-main-map.png"
                  alt="image"
                  >
                  </ImgOurGlobalCommunity>
                </div>
              </OurGlobalCommunity>
              <br />
              <br />
              <br />

              <DivfrequentQuestions>
                    <H1Titles
                      style={{
                        fontFamily: 'Sansation',
                        fontStyle: "normal",
                        fontWeight: "700"
                      }}
                    >Preguntas Frecuentes</H1Titles>
                    <br />
                    <h3 style={{color:"#475467"}}>Todo lo que necesites saber acerca del sistema y pagos</h3>
                    <Collapse 
                    title="¿Cómo puedo participar?"
                    description="Para participar solo tienes que abrir una cuenta y realizar una compra mínima de 100USDT en pre-venta de Tokens ZERO"
                    id="headingOneQuestions"
                    />
                    
                <br />
                <Collapse 
                  title="¿Cómo me beneficio al comprar tokens ZERO en pre-venta?"
                  description="Los tokens ZERO serán lanzados al mercado de criptomonedas con un valor de partida de
                    1USDT por moneda. Reservamos el 20% del total del supply para los miembros que adquieren
                    los tokens en nuestra plataforma con un descuento inicial del 90% por moneda.
                  "
                  id="headingTwoQuestions"
                />
                <br />
                <Collapse 
                  title="¿Cuáles son las formas de pago en la plataforma?"
                  description="Puedes realizar tus compras pagando directamente con Bitcoin a través del proceso de compra o realizar directamente nuevas compras con el saldo de tu plataforma."
                  id="headingThreeQuestions"
                />
                <br />
                <Collapse 
                  title="¿Cómo puedo comprar Bitcoin?"
                  description="Para comprar Bitcoin tienes que tener un wallet en alguna de las diferentes plataformas de compra venta de Bitcoin. Si no sabes como funciona puedes recibir más información: Aquí : https://bitcoin.org/es/como-empezar"
                  id="headingFourQuestions"
                />
                <br />
                {/* <Collapse 
                  title="¿Cómo se reciben rendimientos?"
                  description="Los dividendos son recibidos al añadir tus monedas al pool de staking. El sistema te abonara los rendimientos en el balance de tu plataforma para que puedas realizar el retiro inmediato o la reinversión de los mismos."
                  id="headingFiveQuestions"
                />
                <br /> */}
                {/* <Collapse 
                  title="¿Cuándo se comienza a recibir rendimientos por mis tokens?"
                  description="Nuestro sistema reparte dividendos Trimestral, Semestral y Anualmente. Recibirás los dividendos dentro de esos márgenes según la cantidad de tokens añadidos al pool."
                  id="headingSixQuestions"
                /> */}
                <br />
                <Collapse 
                  title="¿Puedo retirar mis aportaciones?"
                  description="Si, una vez vencidos los contratos del pool, podrás realizar el retiro de los tokens."
                  id="headingThirteenQuestions"
                />
                <br />
                <Collapse 
                  title="¿Puedo incrementar más los beneficios?"
                  description="Si. Nuestro sistema permite a los usuarios, efectuar nuevos depósitos o reinvertir los beneficios generados por la impulsión."
                  id="headingFourteenQuestions"
                />
                <br />
                {/* <Collapse 
                  title="¿Qué es el interés compuesto?"
                  description="El interés compuesto, es aquel que se va sumando al capital inicial y sobre el que se van generando nuevos intereses. En este caso, tiene un efecto multiplicador porque los intereses producen nuevos intereses."
                  id="headingFifteenQuestions"
                />
                <br /> */}
                <Collapse 
                  title="¿Como saco el beneficio obtenido?"
                  description="Tienes varias opciones para sacar los beneficios. Puedes vendernos los token a nosotros ya que te ofrecemos la garantía de recompra de los mismos. También podrás intercambiarlos con otros miembros y obtener el beneficio directamente a través de un acuerdo privado. Y cuando el token este en el mercado podrás solicitar el retiro a tu billetera descentralizada de preferencia y venderlos en el mercado de criptomonedas."
                  id="headingSeventeenQuestions"
                />
                <br />
                <Collapse 
                  title="¿Cómo recibo mis retiros?"
                  description="Si decides retirar las ganancias directamente desde la plataforma, los retiros que solicites, te llegarán de forma automática en Bitcoin a la Wallet que nos hayas indicado."
                  id="headingNineTeenQuestions"
                />
                <br />
                <Collapse 
                  title="¿Qué seguridad me proporciona abrir una cuenta en Zero-Energy?"
                  description="La cuenta que abres en Zero-Energy tiene la opción de configurar el doble factor de autenticación (2FA) para el acceso a la plataforma y para los retiros. Además, para evitar el"
                  id="headingTwentyQuestions"
                />
                <br />
                <Collapse 
                  title="¿Usáis tecnología Blockchain?"
                  description="Si, nuestra plataforma está integrada con la tecnología Blockchain de Bitcoin, lo que nos permite realizar los retiros de forma instantánea y totalmente automática."
                  id="headingTwentyTwoQuestions"
                />
                <br />
                <Collapse 
                  title="¿Puedo recomendarlo a mis amigos?"
                  description="Si, y además por ello queremos premiarte. Hemos desarrollado un programa de referidos con el que podrás beneficiarte generando comisiones por sumar nuevos miembros a la comunidad."
                  id="headingTwentyThreeQuestions"
                />
              </DivfrequentQuestions>
              <br />
              <br />
              <br />
              <DivContainForm id="contact">
              <Contact>
                <Text className="text-light txt-h4" fontSize="22px" color="#1d2b45" fontWeight="bold">
                  Comunicate con nosotros
                </Text>
                <Text
                  fontSize="16px"
                  color="#f8f9fa"
                  maxWidth="300px"
                  fontWeight="400"
                  marginTop="20px"
                  className="txt-p-1"
                >
                  Si tienes cualquier duda o quieres ampliar información ponte en
                  contacto con nosotros y nuestro equipo te asesorará.
                </Text>
                <ContainerForm>
                    <Formik
                      initialValues={{
                        name: "",
                        lastname: "",
                        email: "",
                        message: "",
                      }}
                      validationSchema={Yup.object({
                        name: Yup.string().required("El campo es requerido*"),
                        lastname: Yup.string().required("El campo es requerido"),
                        email: Yup.string()
                          .required("El campo es requerido*")
                          .email("Ingrese un correo electrónico válido*"),
                        message: Yup.string()
                          .min(10, "Mínimo 10 caracteres*")
                          .required("El campo es requerido*"),
                      })}
                      onSubmit={handleClickSend}
                    >
                      {({ values, handleChange, errors }) => {
                        return (
                          <Form>
                            <label for="name" class="form-label text-white mt-3" >Tu nombre</label>
                            <Input
                              id="name"
                              name="name"
                              fullWidth
                              placeholder="Nombre"
                              marginTop="10px"
                              onChange={handleChange}
                              value={values.name}
                              bacgkroundColor="#0000"
                            />
                            {errors && errors.name ? (
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="textErrorLabel"
                              />
                            ) : null}
                            <label for="name" class="form-label text-white mt-3" >Tu apellido</label>
                            <Input
                              id="lastname"
                              name="lastname"
                              fullWidth
                              placeholder="Apellido"
                              marginTop="10px"
                              onChange={handleChange}
                              value={values.lastname}
                              bacgkroundColor="#0000"
                            />
                            {errors && errors.lastname ? (
                              <ErrorMessage
                                name="lastname"
                                component="div"
                                className="textErrorLabel"
                              />
                            ) : null}                                                        
                            <label for="name" class="form-label text-white mt-3" >Tu correo electrónico</label>
                            <Input
                              id="email"
                              name="email"
                              fullWidth
                              placeholder="Correo electrónico"
                              marginTop="10px"
                              onChange={handleChange}
                              value={values.email}
                              bacgkroundColor="#0000"
                            />
                            <ErrorMessage
                              name="email"
                              component="div"
                              className="textErrorLabel"
                            />
                            <label for="name" class="form-label text-white mt-3" >Escribe tu mensaje aquí</label>
                            <TextareaStyle
                              id="message"
                              name="message"
                              placeholder="Escriba su mensaje aquí!"
                              cols="30"
                              rows="10"
                              onChange={handleChange}
                              value={values.message}
                              bacgkroundColor="#0000"
                            />
                            <ErrorMessage
                              name="message"
                              component="div"
                              className="textErrorLabel"
                            />
                            <div className="total-center" style={{ marginTop: "10px" }}>
                              {isLoading ? (
                                <Button
                                small="100%"
                                  className="btn btn-loading btn-lg text-btn"
                                  type="submit"
                                >
                                  Enviar
                                </Button>
                              ) : (
                                <Button small="100%" className="text-btn" type="submit">
                                  Enviar
                                </Button>
                              )}
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </ContainerForm>
                </Contact>
                  <DivContainImgForm>
                    <DivImgForm
                        src="assets/images/9a22a34bbbee85c73554dc19346093f8.jpeg"
                        alt="image"
                        ></DivImgForm>
                  </DivContainImgForm>
              </DivContainForm>
              <FooterPublic></FooterPublic>
    </div>
    );
  };
  export default TestPage;
  // Text and Titles
  const H1Titles = styled.h1`
  font-size: 3rem;
  @media (max-width:400px){
    font-size: 2rem;
  }
  // font-style:italic;
  @media (min-width:1800px){
    font-size: 4rem !important;
  }
  `
  const TextGeneral = styled.p`
    @media (min-width:1800px){
      font-size: 23px !important;
    }
    @media (min-width:2100px){
      font-size: 28px !important;
    }
  `
  // ANIMACIONES
  const animation1 = keyframes`
    0% { margin-top:25% }
    50% { margin-top:0px }
    100% { margin-top:-25%}
  `
  const animation2 = keyframes`
    0% { margin-top:-30%}
    50% { margin-top:0px }
    100% { margin-top:25% }
  `

// BACKGROUND IMAGE MAIN
const ContainImage = styled.div`
  background-image: url("assets/images/bg-main-eneryZero.jpeg");
  height:100vh;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:30px
  color:white;
  overflow-x: hidden;
  background-position-y: center;
  @media (max-width: 768px) {
      font-size:28px
      min-height: 750px;
      max-height: 100vh;
  }
`;
const ContainImage2 = styled.div`
  background-image: url("assets/images/bg-main3-eneryZero.png");
  height:300px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:30px
  color:white;
  overflow-y: hidden;
  background-position-y: bottom;
  @media (max-width: 1000px) {
    height: 350px;
  }
  @media (max-width: 768px) {
    font-size:28px
    height: 700px;
  }
  @media (max-width: 700px) {
    display: grid;
  }
`;
const DivContainWhite = styled.div`
  background:linear-gradient(180deg, #FAFAFA 18.78%, rgba(250, 250, 250, 0.85) 101.59%);
  height:301px;
  width:100%;
  position:absolute;
  @media (max-width: 1000px) {
    height: 351px;
  }
  @media (max-width: 768px) {
    height: 701px;
  }
  @media (max-width: 600px) {
  }
  @media (max-width: 700px) {
  }
`
const ContainSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(161.11deg, rgba(0, 0, 0, 0.45) 14.69%, rgba(0, 0, 0, 0) 86.76%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  color:white;
  @media (max-width:700px){

    section>div>h1{
      font-size: xx-large;
    }
  }
`;
// text

const H4Section = styled.h4`
  @media (max-width:900px){
    font-size: 17px !important;
    margin-bottom: 20px !important;
  }
  @media (min-width:1000px){
    margin-bottom: 20px !important;
    font-size: 20px !important;
  }
`
const H5Section = styled.h5`
  @media (max-width:900px){
    font-size: 18px !important;
    margin-top: 20px !important;
  }
  @media (max-width:400px){
    font-size: 15px !important;
  }
  
  @media (min-width:1000px){
    font-size: 20px !important;
    margin-top: 20px !important;
  }
`
const ContainSection1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color:black;
  z-index: 65;
  padding-right: 10px;
  padding-left: 10px;
  section > h1{
    font-weight: normal !important;
    margin-bottom: 5px;
  }
  section > button{
    font-family:'Roboto' !important;
    font-style: normal;
  }
`;
const SectionContainBg = styled.section`
  max-width:500px;
  @media (max-width:500px){
    max-width:300px;
  }
  @media (min-width:1000px){
    flex: initial;
    width:500px; 
  }
  @media (min-width:1200px){
    width:600px; 
  }
`
const ContantTextBg = styled.div`
line-height: inherit;
  h4,h5{
    line-height: inherit;
  }
  @media (min-width:1000px){
    width:500px; 
    h1{
      font-size: 47px !important;
    }
    h5{
      font-size: 14px !important;
    }
  }
  @media (min-width:1200px){
    width:600px; 
    margin-left: -4%;
    h1{
      font-size: 57px !important;
    }
    h5{
      max-width: 85%;
      margin: auto;
    }
  }
  @media (min-width:1700px){
    h1{
      font-size: xxx-large !important;
    }
  }
  @media (min-width:1800px){
    h1{
      font-size: 52px !important;
    }
  }
`
// IMAGES MAIN
const ContainSectionImage1 = styled.section`
  position: relative;
  left: -9%;
  margin-top: 4vh;
  width: 100%;
  margin-left: -33px;
  @media (max-width:1000px){
    display:none;
  }
  @media (max-width:1200px){
    left: -5%;
  }
  @media (min-width:1600px){
    left: -11%;
  }
  @media (min-width:1700px){
    left: -12%;
  }
  @media (min-width:2000px){
    left: -13%;
  }
  @media (min-width:2100px){
    left: -14%;
  }
  @media (min-width:2300px){
    left: -16%;
  }
`;
const ImgMain1 = styled.img`
  border-radius: 0px 35px 35px 0px;
  width: 100%;
  height: 226px;
  animation-name: ${animation2};
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: linear;
  @media (max-width:1000px){
    display:none;
  }
  @media (min-width:1000px){
    transform: scale(1.3);
    width: 131px;

    transform: scale(1.3);
    height: 290px;
  }
  @media (min-width:1500px){
    height: 350px;
    width: 150px;
  }
  @media (min-width:1600px){
    height: 380px;
    // width: 45%;
  }
  @media (min-width:2100px){
    height: 400px;
  }
`;
const ContainImg2Main = styled.div`
  position: relative;
  width: 300px;
  overflow-y: hidden;
  height: 101%;
  z-index: 65;
  @media (max-width:700px){
    height: 107%;
  }
  @media (min-width:800px){
    height: 104%;
  }
`;
const Img2Main = styled.img`
  z-index: 2;
  width: 100%;
  height: 139px;
  position: absolute;
  right: 0px;
  bottom: 3px;
  @media (max-width:400px){
    right: -10px;
    width: 200px;
  }
  @media (min-width:1000px){
    height: 190px;
  }
`
const Img1Main = styled.img`
  z-index:2;
  width: 80%;
  height: 226px;
  left: -11px;
  top: -95px;
  position: absolute;
  @media (max-width:700px){
    left: -7px;
    width: 145px;
  }
  @media (max-width:600px){
    left: -4px;
  }
  @media (min-width:1000px){
    height: 220px;
    width: 75%;
  }
`
const ContainSectionImage2 = styled.section`
  position: relative;
  width: 100%;
  right: -1%;

  @media (max-width:1000px){
    display:none;
  }
  @media (min-width:1099px){
    right: -5%;

  }
  @media (min-width:1170px){
    right: -6%;
  }
  @media (min-width:1300px){
    right: -7%;
  }
  @media (min-width:1400px){
    right: -9%;
  }
  @media (min-width:1500px){
    right: -13%;
  }
  @media (min-width:1600px){
    right: -14%;
  }
  @media (min-width:1700px){
    right: -15%;
  }
  @media (min-width:2000px){
    right: -17%;
  }
`;
const ImgMain2 = styled.img`
  border-radius: 35px 0px 0px 35px;
  margin-right: -120px;
  height: 270px;
  animation-name: ${animation1};
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: linear;
  @media (max-width:1000px){
    display:none;
  }
  @media (min-width:1100px){
    width: 131px;
  }
  @media (min-width:1500px){
    height: 300px;
    width: 150px;
    margin-right: 0px; 
  }
  @media (min-width:2100px){
    height: 400px;
  }
`;


//------- About us platform -------//
const AboutThePlatform = styled.div`
  @media (min-width:1000px){
    padding-right: 10% !important;
    padding-left:10% !important;
  }
`;

const DivInformationPlatform  = styled.div`
  display:flex;
  flex-wrap: wrap;
  @media (max-width:900px){
    section > h1 {
      font-size: xx-large !important;
    }
  }
`;

const SectionInformationPlatformText1 = styled.section`
  margin-right: auto
  max-width: 50%;
  text-align: justify;
  padding-right: 1rem;
  p{
    color: #616161;
  }
  @media (max-width:900px){
    p{
      padding-left: 7%;
    }
  }
  @media (max-width:900px){
    max-width: 100%;
    padding-right: 0rem;
    padding-bottom:1rem;
    padding-left: 2.5%;
    p{
      padding-left: 4%;
    }
  }
  @media (max-width:800px){
    p{
      padding-left: 5%;
    }
  }
    @media (max-width:650px){
    p{
      padding-left: 6%;
    }
  }
  @media (min-width:1000px){
    max-width: 50%;
    padding-right: 5%;
    p{
      padding-left: 7%;
    }
  }
  @media (min-width:1500px){
    p{
      padding-left: 6%;
    }
  }
  @media (min-width:1700px){
    p{
      padding-left: 5%;
    }
  }
  @media (min-width:1900px){
    p{
      padding-left: 4%;
    }
  }
  `;
const SectionInformationPlatformText2 = styled.section`
  overflow-x:hidden;
  max-width: 50%;
  margin: auto;
  width: 50%;
  text-align: justify;
  margin-top:0;
  p{
    color: #616161;
  }
  @media (max-width:900px){
    max-width: 100%;
    width: 100%;
    padding:auto !important;
    padding-left: 2.5%;
    p{
      padding-left: 4%;
    }
  }
  @media (max-width:800px){
    p{
      padding-left: 5%;
    }
  }
  @media (max-width:650px){
    p{
      padding-left: 6%;
    }
  }
  @media (min-width:1000px){
    p{
      padding-left: 7%;
    }
  }
  @media (min-width:1500px){
    p{
      padding-left: 6%;
    }
  }
  @media (min-width:1500px){
    p{
      padding-left: 5%;
    }
  }
  @media (min-width:1900px){
    p{
      padding-left: 4%;
    }
  }
`;
const ImgDesktopInformationPlataform = styled.img`
  @media (max-width:778px){
    margin-top: 30px;
  }
  @media (min-width:900x){
    margin-left: 15%;
  }

`;
const TextSectionInformationPlatafform = styled.p`
  padding:10px;
  margin-top: 0;
  @media (max-width:900px){
    padding-left:0px;
  }
  @media (min-width:1800px){
    padding-left: 6%;
  }
  @media (min-width:1800px){
    font-size: 23px !important;
  }
`;
const DivImgInformationPlatform = styled.div`
  background-image: url("assets/images/main-platform-trash.jpeg");
  width:100%;
  height:300px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  @media (min-width:1000px){
    height:500px;
  }
`;

//------- Our System -------//

const OurSystem = styled.div`
  @media (max-width:769px){
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  @media (min-width:1000px){
    padding-right: 0 !important;
    padding-left:10% !important;
  }
`;

const SectionInformationTokenZero = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    max-width: 50%;
    width:50%;
    padding-right: 1rem;
    @media (max-width:900px){
      width:max-content;
      margin: auto;
      max-width:100%;
    }
`;

const TextInformationToken = styled.div`

    p{
      max-width:400px;
    }
  @media (max-width:400px){
    h5{font-size: 2rem;}
  }
  @media (min-width:1000px){
    p{
      max-width: 600px;
    }
  }
  padding-left: 10px;
  border-left: 4px solid #F2F4F7;
  :hover{
    border-left: solid #CDA23C;
  }
`;

const DivInformationOurSystem = styled.div`
  display:flex;
  flex-wrap:wrap;
`;

const SectionInformationOurSystem = styled.section`
  h5{
    font-weight: bold;
  }  
  
  @media (max-width:900px){
    width: max-content;
    margin: auto;
    h5{
      font-size: 15px !important;
    }
  }
  @media (max-width:500px){
    margin: auto;
    width: fit-content;
  }
  @media (min-width:910px){
    h5{
      font-size: 16px !important;
    }
  }
`;

const SectionInformationOurSystemText1 = styled.section`
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  max-width: 50%;
  width:50%;
  text-align: justify;
  padding-right: 1rem;
  div>p{
    font-size: 16px !important;
  }
  @media (max-width:900px){
    width:100%;
    max-width:100%;
  }
  @media (min-width:1300px){
    div>p{
      font-size: 20px !important;
    }
  }
`;

//------- Zero Risk -------//



const ZeroRisk = styled.div`
  margin:auto;
  max-width: 920px;
  @media (max-width:768px){
    width:100%;
  }
  @media (min-width:1600px){
    span{
      font-size: 30px !important;
    }
  }
`;
const Images = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (min-width:768px){
    padding-left: 8%;
  }
`;

const Image1 = styled.img`
  max-width:50%;
  animation-name: ${animation1};
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: linear;
`


const Image2 = styled.img`
  max-width:50%;
  margin-top:-40%
  animation-name: ${animation2};
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: linear;
`
const ContVideo = styled.div`
  padding:20px;
  width:100%;
  height:415px;
  margin: auto;

  @media (max-width:768px){
    width:100%;
    height:90%;
  }
  @media (min-width:1000px){
    max-width: 85vw;
    height: 85%;
    padding-bottom: 0;
    
  }
`;

//------- Emissions -------//


const Emissions = styled.div`
  display:flex;
  flex-wrap: wrap;
  background-color:#141414;
  @media (min-width:1000px){
    background-image: url("assets/images/backgroundInclinado-removebg-preview.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100vw 400px;
    background-size: 100vw 100%;
    padding-top: 160px;
    padding-bottom: 50px;

  }
`;
const SectionInformationEmissions = styled.section`
    max-width:50%;
    width:50%;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    @media (max-width:768px){
      width:100%;
      max-width:100%;
      padding-right: 0px;
      padding-top: 20px;
    }
    @media (min-width:1000px){
      padding-left: 4%;
      div>h4{
        font-size: 30px;
      }
      div>h2{
        font-size: 60px;
      }
      div>span{
        font-size: 19px;
      }
    }
`;

const SectionContainImagesEmission = styled.section`
  max-width:50%;
  width:50%;
  padding-left: 60px;

  @media (max-width:768px){
    width:100%;
    max-width:100%;
    padding-left: 0px;
  }
`;
const ContainButtonsEmission = styled.div`
  display:flex;
  Font style: Bold;
  padding-top: 15px;
  @media (max-width:768px){
    display: flex !important;
    flex-flow: column;
  }
  @media (min-width:1000px){
    button{
      max-width: 230px;
    }
  }
`;
const TokenZero = styled.div`
  padding: 40px;
  background-color: #E0E0E0;
  @media (min-width:1000px){
    padding: 50px 80px 50px 80px;
  }
`;
const ContainTokenZeroImg = styled.div`
  background-image: url("assets/images/token-zero-main-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  border-radius: 10px;
  `;
  const ContainBackgroundColorToken = styled.div`
  border-radius: 10px;
  display:flex;
  padding: 40px;
  flex-wrap:wrap;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.42620798319327735) 100%);
    @media (max-width:900px){
      display: block;

      div > img{
        max-width: 100%;
      } 
    }


  `;

  const TokenLogoZeroEnergy = styled.div `
  display:flex;
  justify-content:center;
  max-width: 50%;
  width: 50%;
  text-align: justify;

  @media (max-width:1000px){
    margin-left: auto;
    margin-right: initial;
    max-width: 125px;
    height: 100px;
    margin-top: a;
    margin-top: auto;
    margin-bottom: auto;
  }
  @media (max-width:900px){
    margin-left: auto;

    margin-right: auto;
  }
  @media (max-width:768px){
    max-width: 125px;
    height: 100px;
    padding:auto !important;
  }
  @media (min-width:1000px){
    margin-left: auto;
    margin-right: initial;
    max-width: 140px;
    height: 120px;
    margin-top: a;
    margin-top: auto;
    margin-bottom: auto;

  }
  @media (min-width:1300px){
    max-width: 150px;
  }
  `;

//------- Advantages -------//

const Advantages = styled.div`
  overflow-x: hidden;
  background-color:#141414;
  padding: 40px;
`;
const AdvantagesText = styled.div`
  display:flex;
  @media (max-width:780px){
    display:block;
  }
`;
const ButtonCustomArrows = styled.div`
  cursor:pointer;
  border-radius: 74%;
  max-width: 44px;
  width: 41px;
  font-size: 27px;
  text-align: center;
  background-color: white;
  border: 1px solid #D8D8D8;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color:white;
  backdrop-filter: blur(12px);
`;
const Card = styled.div`
  color:white;
  display: inline-block;
  padding: 10px;
  margin-bottom:0px;
  height:100%;
  background: linear-gradient(351.97deg, #C4A861 -32.38%, #906126 23.06%, #D5BF74 69.28%, #A78040 113.31%);
  box-shadow:none;
  border-radius: 0px !important;
`;
const SectionCardSlides = styled.section`
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding:0.5rem;
  backdropFilter: blur(12px);
  margin: auto;
  bottom: 10px;
  width: 100%;

  @media (max-width:1000px){
    height: 60%;
  }
  @media (max-width:900px){
    height: 63%;
    width: 98%;
  }
  @media (max-width:860px){
    height: 65%;
  }
  @media (max-width:840px){
    height: 67%;
  }
  @media (max-width:800px){
    height: 69%;
    width: 98%;
  }
  @media (max-width:750px){
    height: 71%;
    width: 97%;
  }
  @media (max-width:710px){
    height: 73%;
  }
  @media (max-width:700px){
    height: 56%;
  }
  @media (max-width:680px){
    height: 60%;
  }
  @media (max-width:565px){
    height: 63%;
  }
  @media (max-width:550px){
    height: 65%;
  }
  @media (max-width:500px){
    height: 60%;
  }
  @media (max-width:450px){
    height: 63%;
  }
  @media (max-width:400px){
    height: 67%;
  }
  @media (max-width:370px){
    height: 69%;
  }
  @media (max-width:340px){
    height: 73%;
  }


  @media (min-width:1000px){
    height: 71%;
    div>h5{
      font-size: 2.1rem;
    }
    div>p{
      font-size: 24px !important;
    }
    width: 95%;
  }
  @media (min-width:1100px){
    width: 90%;
  }

  @media (min-width:1590px){
    height: 71%;
    
    h5{
      font-size: 30px;
    }
    p{
      font-size: 25px !important;
    }
  }  
  @media (min-width:1800px){
    height: 74%;
    h5{
      font-size: 40px;
    }
    p{
      font-size: 30px !important;
    }
  }
  @media (min-width:2000px){
    height: 74%;
  }

`;


  //------- Divparticipate -------//


const Divparticipate = styled.div`
  padding: 40px;
  @media (max-width:1000px){
    p{font-size: 19px !important}
  }
  @media (min-width:1000px){
    padding-right: 10% !important;
    padding-left:10% !important;
    h1{
      font-size: xxx-large !important;
    }
  }
  @media (min-width:1500px){
    h1{font-size: 4rem !important;}
  }
  p{
    font-family: 'Roboto' !important;
    color: #475467;
  }
`;
const CardParticipate = styled.div`
  margin: 10px;
  width: 22rem;
  @media (max-width:1300px){
    width:15rem;
  }
  @media (max-width:700px) {
    width:90%;
    margin:auto;
    margin-top:20px;
  }

  @media (min-width:1000px){
    width: 20rem;
  }
`;
const ContainCards = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  @media (max-width:900px) {
  }
`;

//------- Roadmap -------//
const ContainRoadmapImage = styled.div`
  @media (max-width:1000px) {
    display:none;
  }
  img{
    padding-bottom: 9px;
    background: linear-gradient(90deg, rgba(144,97,38,1) 0%, rgba(237,223,143,1) 100%);
  }

  width: 50%;
`;
const ContainInformationRoadMap = styled.div`
  width:50%;
  @media (max-width:1000px) {
    width:100%;
  }
`;
const CardHeader = styled.div`
  h5>button{
    text-decoration: none;
  }
  @media (max-width:600px){
    display:block;
    p{
      width: 90%;
      padding-left: 2%;
    }
  }    
  @media (max-width:500px){
    padding-left: 0;
    padding-right: 0;
    h5 > button > h1  , h5 > button > li > h1{
      font-size: 30px !important;
    }
    h5 > button > li  > span{
      padding: 5px 10px 5px 10px;
    }
  }
`;

const Roadmap = styled.div`
  padding: 40px;
  display:flex;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  justify-content: space-between;
  @media (min-width:1000px){
    padding-right: 10% !important;
    padding-left:10% !important;
  }
`;
const RoadMapImage = styled.img`
  @media (max-width:400px){
    h1{
      font-size: 2rem !important;
    }
  }
`;
  //------- Our global community just keeps growing! -------//


const OurGlobalCommunity = styled.div`
  justify-content: center;
  display: flex;
  padding-top: 30px;
  background: linear-gradient(178.02deg, rgba(26, 26, 26, 0) 61.36%, rgba(55, 47, 29, 0.05) 89.27%, rgba(167, 128, 64, 0.25) 110.41%), #1A1A1A;
`
const ImgOurGlobalCommunity = styled.img`
  @media (min-width:1000px){
    max-width: 75%;
  }
`
  //-------   frequent questions -------//

const DivfrequentQuestions = styled.div`
  padding: 40px;
  text-align:center;
  @media (min-width:900px){
    padding: 40px 15% 40px 15%;
  }
`;
const SpanPlus = styled.span`
  border: solid 1px;
  padding: 10px 15px 10px 15px;
  border-radius: 70px;
  font-size: 20px;
`;

const LiOftenQuestions = styled.li`
`;
  //------- FORM -------//

  const DivContainForm = styled.div`
    padding-left: 5%;
    padding-bottom: 5%;
    padding-right: 5%;
    @media (max-width:1000px){
      overflow: hidden;
      padding:0%;
    }
  `;
  const DivContainImgForm = styled.div`
  display: flex;
  @media (max-width:1000px){
    height: 300px;
    overflow: hidden;
    width: 100%;
  }
  @media (max-width:650px){
    width: 700px;
    margin-left: -6%;
  }
  @media (max-width:600px){
    margin-left: -14%;
  }
  @media (max-width:550px){  
    margin-left: -17%;
  }
  @media (max-width:500px){
    width: 690px;
    margin-left: -23%;
  }
  @media (max-width:450px){
        margin-left: -28%;
  }
  @media (max-width:420px){
    margin-left: -33%;
  }
  @media (max-width:400px){
    margin-left: -48%;
  }
  @media (max-width:390px){
    margin-left: -39%;
  }
  @media (max-width:370px){
    margin-left: -46%;
  }
  @media (max-width:350px){
    margin-left: -55%;
  }
  @media (min-width:1000px){
    margin-top: -400px;
    overflow: hidden;
  }
  `;
  const DivImgForm = styled.img`
  background-image: url(assets/images/9a22a34….jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  @media (max-width:1000px){
    height: 500px;
    width: 100%;
    -webkit-transform: scale(1.3);
    transform: scale(2.3);
    padding-top: 30px;
    margin-left: -12%;
  }
  @media (min-width:1000px){
    z-index: -123123213123213213;
    height: 400px;
    width: 100%;
    transform: scaleY(1.9);
  }
  `;
  const Contact = styled.div`
  margin-left: 33px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: max-content;
  padding: 15px;
  background-color: #1A1A1A;
  @media (max-width:1000px){
    padding: 15px;
    margin: auto;
    margin-bottom: 20px;
    width: 89%;
  }
  @media (max-width:400px){
    width: 95%;
  }
  @media (min-width:1100px){
    width:400px;
    max-width: 400px;
    p{
    width: 377px;
    max-width: 377px;
    }
  }
`;
const Text = styled.p`
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  font-family: "Poppins";
  font-size: ${(props) => props.fontSize || "26px"};
  font-weight: ${(props) => props.fontWeight || 400};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: ${(props) => props.textAlign || "center"};
  color: ${(props) => props.color || "#1d2b45"};
  margin-top: ${(props) => props.marginTop};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  margin-left: ${(props) => props.marginLeft};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  @media (max-width: ${(props) => props.sizeLg || "992px"}) {
    font-size: ${(props) => props.fontSizeLg};
    text-align: ${(props) => props.textAlignLg};
  }
  @media (max-width: ${(props) => props.sizeMd || "768px"}) {
    font-size: ${(props) => props.fontSizeMd};
    text-align: ${(props) => props.textAlignMd};
  }
  @media (max-width: ${(props) => props.sizeSm || "620px"}) {
    font-size: ${(props) => props.fontSizeSm};
    text-align: ${(props) => props.textAlignSm};
  }
`;
const ContainerForm = styled.div`
  width: 95vw;
  max-width: 300px;
  margin-top: 20px;
  padding-bottom: 15px;
  @media (min-width:1100px){
    max-width: 350px;
  }
`;
const TextareaStyle = styled.textarea`
  width: 100%;
  height: 150px;
  border: 1px solid #c8cfd8;
  border-radius: 5px;
  padding: 5px;
  outline: none;
  color: #686461;
  background-color:#0000;
  margin-top: 10px;
  :focus {
    outline: none !important;
    border-color: #3f768a;
  }
  ::placeholder {
    padding-left: 5px;
    color: #728096 !important;
    font-weight: 400 !important;
  }
`;
