import axios from "axios";
// import swal from "@sweetalert/with-react";
// eslint-disable-next-line import/no-cycle
import { store } from "./App";
import { BASE_URL } from "./constants";
import { LOGOUT } from "./ducks/access/types";
// import { openInfoModal } from "./ducks/general/actions";
// import { LOGOUT } from "./ducks/access/types";

const API = axios.create({
  baseURL: BASE_URL,
  timeout: 30000000,
});

API.interceptors.request.use(
  async (config) => {
    const { token } = store.getState().accessStore;
    const newConfig = { ...config };
    if (token && !config.headers.noAuth) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    } else if (config.headers.noAuth) {
      delete newConfig.headers.noAuth;
    }
    return newConfig;
  },
  (error) => Promise.reject(error)
);

API.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response ? error.response.status : -1;
    // console.log(error);
    switch (status) {
      // case 500:
      //   break;
      // case -1:
      //   break;
      // case 400:
      //   break;
      case 401:
        console.log('se ejecuta..')
        store.dispatch({ type: LOGOUT });
        // Se retorna un objeto vacio para evitar que caiga en el catch de la ejecucion de la llamada
        // y evitar que se muestren otros mensaje diferentes al que se esta mostrando.
        return {};

      // case 404:
      //   break;
      default:
        break;
    }

    // Se retorna Promise.reject para que el error caiga en el catch de la ejecucion de la llamada

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({ ...error });
  }
);

export default API;
