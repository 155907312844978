import React, { useEffect } from "react";
import styled from "styled-components";
import FooterPublic from "../components/layout/FooterPublic";
import HeaderPublic from "../components/layout/HeaderPublic";

const Privacy = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <HeaderPublic history={props.history} />
        <ContainImage>
          <p> POLITICA DE PRIVACIDAD </p>
        </ContainImage>
      <div className="total-center bg-white">

        <Text>
          {/* <span> Políticas de Privacidad </span> */}

          Respetamos la privacidad de los usuarios de nuestro Servicio y los visitantes
          de nuestra página web ("usted") y estamos comprometidos a proteger su privacidad
          en línea. Esta Política de privacidad resume los procedimientos de Zero-Energy
          (en lo sucesivo, la "Compañía") que rigen la recopilación, el mantenimiento y
          el manejo de sus Datos personales. Es posible que actualicemos nuestra Política
          de privacidad de vez en cuando. Le notificaremos de cualquier cambio publicando
          la nueva Política de privacidad en esta página. Se le recomienda que revise esta
          Política de privacidad periódicamente para ver si hay cambios. Los cambios a esta
          Política de privacidad entran en vigencia cuando se publican en esta página.
          <br />
          1. RECOPILACIÓN DE INFORMACIÓN
          <br />
          Usamos sus Datos Personales para proporcionar y mejorar nuestros
          servicios de blockchain alojados (el "Servicio") y mejorar su experiencia
          de la Página Web. “Datos personales” significa cualquier información
          relacionada con una persona identificada o identificable. Recopilamos
          la información que nos proporciona al crear una cuenta para el Servicio.
          También utilizamos diversas tecnologías para recopilar y almacenar información,
          incluidas cookies, etiquetas de píxeles, almacenamiento local, como almacenamiento
          web del navegador o cachés de datos de aplicaciones, bases de datos y registros del
          servidor. Al utilizar el Servicio o aceptar los términos de nuestra página web,
          acepta la recopilación y el uso de la información de acuerdo con esta Política
          de privacidad. A menos que se defina lo contrario en esta Política de privacidad,
          los términos utilizados en esta Política de privacidad tienen el mismo significado
          que en nuestro Contrato en la nube, accesible en www.zeroenergy.pro
          <br />
          <br />
          1.1. INFORMACIÓN DE CREACIÓN DE CUENTA
          <br />
          Cuando se registra en el Servicio o crea una cuenta en la página web, recopilamos
          y usamos los Datos personales que nos proporciona, como su nombre, dirección de
          correo electrónico o información de facturación, información de tarjeta de crédito,
          dirección de billetera de criptomonedas, dirección IP , u otros datos que puedan estar
          razonablemente vinculados a dicha información, como la información que asociamos con
          su Cuenta y el Servicio que se le brinda. También se le pedirá que elija un nombre de
          usuario y una contraseña únicos para registrarse en el Servicio, cuya información se
          utilizará únicamente con el fin de proporcionar acceso a su Cuenta. 
          Al registrarse, tendrá la opción de proteger su cuenta habilitando la 
          Autenticación de Google en la página de Configuración. 
          <br />
          1.2. INFORMACIÓN DE REGISTRO
          <br />
          La Compañía recopila información que su navegador envía cada vez que visita nuestra
          página web e inicia sesión en su cuenta (en lo sucesivo, "Datos de registro"). 
          Estos Datos de registro pueden incluir información como la dirección de Protocolo 
          de Internet de su computadora (en adelante, una "IP"), el tipo de navegador, 
          la versión del navegador, las páginas de nuestro Servicio que visita, la hora 
          y fecha de su visita, el tiempo dedicado en esas páginas y otras estadísticas.
           Además, podemos utilizar servicios de terceros como Google Analytics que recopilan,
          monitorean y analizan este tipo de información para aumentar la funcionalidad de 
          nuestro Servicio. Estos proveedores de servicios de terceros tienen sus propias 
          políticas de privacidad que abordan cómo usan dicha información.
          <br />
          2. PROVEEDORES DE SERVICIOS
          <br />
          Podemos contratar empresas e individuos de terceros para facilitar nuestro Servicio,
          para proporcionar el Servicio en nuestro nombre, para realizar servicios relacionados
          con el Servicio o para ayudarnos a analizar cómo se utiliza nuestro Servicio. 
          Estos terceros tienen acceso a sus Datos personales solo para realizar estas 
          tareas en nuestro nombre y están obligados a no divulgarlos ni utilizarlos para 
          ningún otro propósito.
          <br />
          3. CUMPLIMIENTO DE LAS LEYES
          <br />
          Divulgaremos sus Datos personales cuando así lo exija la ley o si creemos que 
          dicha acción es necesaria para cumplir con la ley y las solicitudes razonables 
          de las fuerzas del orden público o para proteger tal como se establece en el 
          Reglamento general de protección de datos de la Unión Europea. Los residentes 
          de la Unión Europea, el Espacio Económico Europeo y Suiza que visitan la página 
          web o utilizan el Servicio tienen los siguientes derechos legales de protección 
          de datos de acuerdo con GDPR (Reglamento General de Protección de Datos de la UE 2016/679).
          <br />
          4. SEGURIDAD DE LA INFORMACIÓN
          <br />
          La Compañía ha adoptado prácticas de recopilación, almacenamiento y procesamiento de datos
          y medidas de seguridad adecuadas para la industria, diseñadas de manera razonable, así como
          medidas de seguridad física para proteger contra el acceso no autorizado, la alteración,
          la divulgación o la destrucción de sus Datos personales, nombre de usuario, contraseña,
          información de transacciones y datos. almacenado en su cuenta de usuario. 
          Nuestros empleados tienen restringido el acceso a los nombres de los usuarios
          y las direcciones de correo electrónico si lo necesitan. La seguridad de los
          datos personales es importante para nosotros, pero les recordamos a los 
          usuarios que ningún método de transmisión a través de Internet o método 
          de almacenamiento electrónico es 100% seguro. Si bien nos esforzamos por 
          utilizar medidas y herramientas efectivas para la protección de sus datos 
          personales, no podemos garantizar el 100% de seguridad y protección de dicha información.
          <br />
          5. TRANSFERENCIAS INTERNACIONALES DE DATOS
          <br />
          Su información, incluidos los Datos personales, puede transferirse y mantenerse 
          en computadoras y sistemas ubicados fuera de su país, estado, provincia u otra 
          jurisdicción gubernamental donde las leyes de protección de datos pueden diferir 
          de las de su jurisdicción. Su consentimiento a esta Política de privacidad seguido 
          de su envío de dicha información representa su aceptación de dichas transferencias. 
          Protegeremos la privacidad y la seguridad de los Datos personales que recopilamos 
          de acuerdo con esta Política de privacidad, independientemente de dónde se 
          procesen o almacenen.
          <br />
          6. ENLACES A OTRAS PÁGINAS WEB
          <br />
          Nuestro Servicio puede contener enlaces a otras páginas web que no son operadas por nosotros. 
          Si hace clic en el enlace de un tercero, se le dirigirá a la página web de ese tercero.
          Le recomendamos encarecidamente que revise la Política de privacidad de cada página web que visite. 
          No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad 
          o las prácticas de las páginas web o los servicios de terceros.
          <br />
          7. PRIVACIDAD DE LOS NIÑOS
          <br />
          La página web no está dirigida a personas menores de dieciocho años. 
          En general, y con ciertas excepciones, las leyes de privacidad, incluido 
          el Reglamento General de Protección de Datos, generalmente requieren que 
          la recopilación de Datos Personales de personas menores de dieciséis años 
          (en adelante, los "Niños") requiera el consentimiento de los padres.
          Además, si el estado miembro de la Unión Europea no ha proporcionado 
          un límite de edad inferior (siempre que dicha edad inferior no sea menor de 13 años), 
          el Reglamento general de protección de datos obliga a los padres a dar su consentimiento.
          Si usted es un padre o tutor y sabe que sus hijos nos han proporcionado datos personales, 
          comuníquese con nosotros. Si descubrimos que una persona menor de 16 años nos ha proporcionado 
          datos personales, eliminaremos dicha información de nuestros servidores de inmediato.
          <br />
          8. CAMBIOS EN SUS DATOS PERSONALES
          <br />
          En caso de que decidamos utilizar sus Datos personales para cualquier otro propósito que no 
          sea el indicado en esta Declaración de política de privacidad, le ofreceremos una forma eficaz 
          de optar por no utilizar sus Datos personales para esos otros fines. De vez en cuando, podemos 
          enviarle correos electrónicos sobre nuevos servicios, lanzamientos y próximos eventos. 
          Puede optar por no recibir mensajes de la Compañía seleccionando la función 'cancelar 
          suscripción' presente en cada correo electrónico que enviamos. Sin embargo, los usuarios 
          del Servicio continuarán recibiendo correos electrónicos esenciales relacionados con el Servicio.
          <br />
          9. ALMACENAMIENTO
          <br />
          Almacenaremos sus datos personales durante el tiempo que sea necesario para los fines establecidos 
          en esta Política de Privacidad y para satisfacer nuestras obligaciones contractuales y legales. 
          También podemos almacenar sus datos personales para otros fines si o mientras la ley nos permita 
          almacenarlos para fines particulares, incluso para defensas contra reclamos legales.
          <br />
          10. CÓMO CONTACTARNOS
          <br />
          Si tiene alguna pregunta sobre esta Política de privacidad, comuníquese con nosotros por correo electrónico a info@zeroenergy.pro

        </Text>
      </div>
      <FooterPublic />
    </div>
  );
};

const Text = styled.p`
  margin-top: 30px;
  width: 95vw;
  max-width: 1200px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #1d2b45;
  span {
    font-weight: bold;
  }
`;

const ContainImage = styled.div`
  background-image: url("assets/images/b35c816f4f6eb0c6203a472e9dd6a3f2.jpeg");
  height:400px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:30px
  color:white;
  background-position-y: center;
  @media (max-width: 768px) {
    font-size:28px
  }
`;


export default Privacy;
