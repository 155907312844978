import React, { useEffect } from "react";
import styled from "styled-components";
import FooterPublic from "../components/layout/FooterPublic";
import HeaderPublic from "../components/layout/HeaderPublic";

const TermsAndConditions = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <HeaderPublic history={props.history} />
      <ContainImage>
          <p> TERMINOS Y CONDICIONES </p>
      </ContainImage>
      <div className="total-center bg-white">
        <Text>
          Estos términos de servicio, junto con cualquier otro anexo, términos y condiciones
          o documentos incorporados expresamente en el presente, constituyen el acuerdo completo
          entre las partes (en conjunto, los "Términos"). En el caso de cualquier inconsistencia
          entre estos términos de servicio y cualquiera de los otros apéndices, términos y documentos,
          las disposiciones de estos términos de servicio prevalecerán en la medida de dicha inconsistencia.
          Toda la demás información proporcionada en la Plataforma Zero-Energy o 
          las declaraciones orales / escritas realizadas están excluidas de estos Términos 
          y no constituyen un acuerdo legal entre las partes. El uso de la Plataforma 
          Zero-Energy y los Servicios se rigen por estos Términos.
          <br />
          Al acceder, ver o descargar información de la Plataforma Zero-Energy y utilizar
          los Servicios proporcionados por la Compañía Zero-Energy, usted reconoce que ha
          leído y considerado, y comprende y acepta incondicionalmente estar obligado por estos Términos.
          Zero-Energy puede, en cualquier momento y sin previo aviso, modificar los Términos. Usted acepta
          seguir estando sujeto a dichos Términos modificados y que Zero-Energy no tiene la obligación de notificarle
          dichos cambios. Usted reconoce que es su responsabilidad revisar estos Términos periódicamente para
          ver si hay cambios y que su uso continuo de la Plataforma Zero-Energy y los Servicios ofrecidos por
          la Compañía Zero-Energy después de la publicación de cualquier cambio en los Términos indica su
          aceptación de dichos cambios.
          <br />
          La Plataforma Zero-Energy y los derechos de autor de todos los textos, gráficos, imágenes,
          software y cualquier otro material de la Plataforma Zero-Energy son propiedad de Zero-Energy,
          incluidas todas las marcas comerciales y otros Derechos de propiedad intelectual con respecto
          a los materiales y Servicios de la Plataforma Zero-Energy. Los materiales de la Plataforma Zero-Energy
          solo se pueden utilizar para uso personal y con fines no comerciales.
          <br />
          Puede mostrar en la pantalla de una computadora o imprimir extractos de la Plataforma Zero-Energy
          para los fines mencionados anteriormente solo siempre que conserve los derechos de autor y otros
          avisos de propiedad o cualquiera de las marcas comerciales o logotipos de Zero-Energy, 
          como se muestra en la impresión inicial o descargar sin alteración, adición o eliminación.
          Salvo que se indique expresamente en este documento, no puede sin el permiso previo por
          escrito de Zero-Energy alterar, modificar, reproducir, distribuir o utilizar en cualquier
          otro contexto comercial cualquier material de la Plataforma Zero-Energy. Usted reconoce que
          "Zero-Energy y el logotipo de Zero-Energy son marcas comerciales de Zero-Energy. 
          Puede reproducir dichas marcas comerciales sin alterar el material descargado de la 
          Plataforma Zero-Energy en la medida autorizada anteriormente, pero no puede usarlas, 
          copiarlas, adaptarlas o borrarlas de otra manera".
          <br />
          En ninguna circunstancia obtendrá ningún derecho sobre o con respecto a la Plataforma
          Zero-Energy (que no sean los derechos de uso de la Plataforma Zero-Energy de conformidad
          con estos Términos y cualquier otro término y condición que rija un servicio o sección
          en particular de la Plataforma Zero-Energy) o se declara tener tales derechos sobre o con
          respecto a la Plataforma Zero-Energy.
          <br />
          No se le permite acceder o utilizar los Servicios o la Plataforma Zero-Energy si está 
          ubicado, incorporado o establecido de otra manera en, o es ciudadano o 
          residente de: (i), Siria, Norte Corea o Sudán; (ii) una jurisdicción en 
          la que sería ilegal de acuerdo con la Ley Aplicable que usted (por razón
          de su nacionalidad, domicilio, ciudadanía, residencia u otro) acceda o 
          utilice los Servicios o la Plataforma Zero-Energy; o (iii) cuando la 
          publicación o disponibilidad de los Servicios o la Plataforma Zero-Energy 
          esté prohibida o sea contraria a la ley o regulación local, o podría someter a 
          cualquier miembro de Zero-Energy a cualquier requisito de registro o licencia local 
          (en conjunto, el "Restricted Jurisdicciones ”). Zero-Energy puede, a su sola discreción, 
          implementar controles para restringir el acceso a los Servicios o la Plataforma Zero-Energy 
          en cualquiera de las Jurisdicciones restringidas. Si Zero-Energy determina que está accediendo 
          a los Servicios o la Plataforma Zero-Energy desde cualquier Jurisdicción restringida, o ha hecho 
          declaraciones falsas sobre su ubicación de incorporación, establecimiento, ciudadanía o lugar de 
          residencia, Zero-Energy se reserva el derecho de cerrar cualquiera de sus cuentas. 
          Inmediatamente y liquidar las posiciones existentes.
          <br />
          Descargo de responsabilidad: este documento no es una oferta para vender valores de ningún
          fondo de inversión ni una solicitud de ofertas para comprar dichos valores o activos digitales.
          Cualquier decisión de participar debe basarse únicamente en la evaluación del inversor y el apetito
          por el riesgo del inversor, independientemente de cualquier información que los inversores puedan
          haber recibido de otro modo, incluido este documento. El resumen proporcionado en este documento
          de los términos y condiciones de Zero-Energy no pretende ser completo. Todos los documentos de
          Zero-Energy deben leerse en su totalidad antes de participar en la Plataforma Zero-Energy. 
          La participación en cualquier activo, estrategia o producto digital, incluida la estrategia
          aquí descrita, implica un alto grado de riesgo. No hay garantía de que se logre el objetivo
          de inversión. El desempeño pasado de estos productos y objetivos no es necesariamente 
          indicativo de resultados futuros. Existe la posibilidad de pérdida y toda inversión 
          implica un riesgo, incluida la pérdida del principal. Los valores o activos digitales 
          de la plataforma no están registrados con ninguna autoridad reguladora, se ofrecen de 
          conformidad con las exenciones de dicho registro y están sujetos a restricciones 
          significativas. Los resultados de desempeño se presentan únicamente con fines informativos 
          e ilustrativos y reflejan el impacto que los factores económicos y de mercado materiales 
          tuvieron en el proceso de toma de decisiones de los participantes. No se hace ninguna 
          representación de que algún participante pueda o pueda lograr ganancias o pérdidas 
          similares a las mostradas. Además, los resultados de desempeño presentados representan 
          una interpretación de los resultados de desempeño de un comercio y tecnología patentados 
          administrados por Zero-Energy, con un programa de recompensas similar al programa 
          anticipado de la plataforma. Los rendimientos acumulativos se calculan netos de las 
          comisiones por operaciones y sin incluir todas las demás comisiones, incluida la 
          asignación de incentivos; sin embargo, el desempeño de los participantes individuales 
          puede variar según los diferentes arreglos de asignación inventiva y el momento de las 
          contribuciones y retiros. La Plataforma Zero-Energy no puede garantizar ni predecir un 
          resultado similar con respecto a los participantes en la Plataforma o cualquiera de sus 
          productos en el futuro. 
          <br />
          Los rendimientos proyectados incluyen los dividendos de reinversión y otras ganancias,
          pero no dan efecto a marcas de agua altas, si las hubiera. La información proporcionada
          es histórica e interpretativa y no es una guía para el desempeño futuro. Los participantes
          deben ser conscientes de que es posible una pérdida total de activos digitales. Los gráficos,
          tablas y otras ayudas visuales se proporcionan únicamente con fines informativos. 
          Ninguno de estos gráficos, tablas o ayudas visuales pueden utilizarse por sí mismos 
          para tomar decisiones de inversión. No se hace ninguna representación de que estos 
          ayudarán a cualquier persona a tomar decisiones de inversión y ningún gráfico, 
          cuadro u otra ayuda visual puede capturar todos los factores y variables necesarios 
          para tomar tales decisiones.
        </Text>
      </div>
      <FooterPublic />
    </div>
  );
};

const Text = styled.p`
  margin-top: 30px;
  width: 95vw;
  max-width: 1200px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #1d2b45;
  span {
    font-weight: bold;
  }
`;
const ContainImage = styled.div`
  background-image: url("assets/images/d89199583e2d6fb1fd56b6718cc50dd7.jpeg");
  height:400px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:30px
  color:white;
  background-position-y: center;
  @media (max-width: 768px) {
    font-size:28px
  }
`;

export default TermsAndConditions;
