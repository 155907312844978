import React from "react";
import styled,{keyframes} from "styled-components";
import HeaderPublic from "../components/layout/HeaderPublic";

import Button from "../components/Button";
import FooterPublic from "../components/layout/FooterPublic";
const Maintaining = (props) => {

    return (
        <div>
            <HeaderPublic history={props.history} />
            <ContainImage>
                <ContainSection>
                    <ContainSectionImage1>
                        <ImgMain1 src="/assets/images/backgroundEdit2.png" alt="" /> 
                    </ContainSectionImage1>
                    <SectionContainBg>

                      <ContantTextBg>
                        {/* <H4Section className="mb-2 txt-h4">La Plataforma de Cryptominería que da  </H4Section> */}
                        <h1 className="mb-0 txt-h1 font-sensation" >
                            Estamos optimizando nuestros procesos regresaremos en breves momentos
                        </h1>
                        {/* <H5Section className="mt-2 txt-h5">
                        "Convierte la basura en Cryptoactivos: una nueva era para la
                        Cryptomineria usando los residuos como fuente de energía con Zero-Energy"
                        </H5Section> */}
                      </ContantTextBg>
                    
                    </SectionContainBg>
                    <ContainSectionImage2>
                        <ImgMain2 src="/assets/images/backgroundEdit1.png"  alt=""/>
                    </ContainSectionImage2>
                </ContainSection>
            </ContainImage>
            <ContainImage2>
                <ContainImg2Main>
                        <Img1Main src="/assets/images/e_recortada.png" alt="" />
                </ContainImg2Main>
                <ContainSection1  id="aboutus">
                    <section>
                        <h1 className="txt-h1">Convertimos la basura en Bitcoin</h1>
                        <p className="txt-h4 mb-4">Transforma los desechos en Cryptoactivos.</p>
                        <Button
                            className="btn-lg p-0 text-white"
                            width="200px"
                            small="200px"
                            height="45px"
                            font="18px"
                            href="zero/#/register" /* TODO dont use hardcoded links..  use variables event the crap of hardcoded links .. peding for me mckaygerhard*/
                        >
                            Únete al cambio
                        </Button>
                    </section>
                </ContainSection1>
                <ContainImg2Main style={{marginLeft:"auto"}}>
                        <Img2Main src="/assets/images/+_recortada.png" alt="" />
                </ContainImg2Main>
                <DivContainWhite> </DivContainWhite>
            </ContainImage2>

            <FooterPublic />
        </div>
    )
}
// ANIMACIONES
const animation1 = keyframes`
    0% { margin-top:25% }
    50% { margin-top:0px }
    100% { margin-top:-25%}
`
const animation2 = keyframes`
    0% { margin-top:-30%}
    50% { margin-top:0px }
    100% { margin-top:25% }
`
// BACKGROUND IMAGE MAIN
const ContainImage = styled.div`
    background-image: url("assets/images/bg-main-eneryZero.jpeg");
    height:100vh;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:30px
    color:white;
    overflow-x: hidden;
    background-position-y: center;
    @media (max-width: 768px) {
        font-size:28px
        min-height: 750px;
        max-height: 100vh;
    }
`;
const DivContainWhite = styled.div`
  background:linear-gradient(180deg, #FAFAFA 18.78%, rgba(250, 250, 250, 0.85) 101.59%);
  height:301px;
  width:100%;
  position:absolute;
  @media (max-width: 1000px) {
    height: 351px;
  }
  @media (max-width: 768px) {
    height: 701px;
  }
  @media (max-width: 600px) {
  }
  @media (max-width: 700px) {
  }
`
const ContainImage2 = styled.div`
    background-image: url("assets/images/bg-main2-eneryZero.jpeg");
    height:300px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size:30px
    color:white;
    background-position-y: center;
    @media (max-width: 768px) {
        font-size:28px
    }
`;
const ContainSection = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(161.11deg, rgba(0, 0, 0, 0.45) 14.69%, rgba(0, 0, 0, 0) 86.76%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
    color:white;
    @media (max-width:700px){
        section>div>h1{
            font-size: xx-large;
        }
    }
`;
const ContainSection1 = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: linear-gradient(180deg, #FAFAFA 18.78%, rgba(250, 250, 250, 0.85) 101.59%);
    color:black;
    z-index: 1111111111111111111111111111111111;
`;
// IMAGES MAIN 
const ContainSectionImage1 = styled.section`
    position: relative;
    left: -9%;
    margin-top: 4vh;
    width: 100%;
    margin-left: -33px;
    @media (max-width:1000px){
        display:none;
    }
    @media (max-width:1200px){
        left: -5%;
    }
    @media (min-width:1600px){
        left: -11%;
    }
    @media (min-width:1700px){
        left: -12%;
    }
    @media (min-width:2000px){
        left: -13%;
    }
    @media (min-width:2100px){
        left: -14%;
    }
    @media (min-width:2300px){
        left: -16%;
    }
`;
const Img1Main = styled.img`
  z-index:2;
  width: 80%;
  height: 226px;
  left: -11px;
  top: -95px;
  position: absolute;
  @media (max-width:700px){
    left: -7px;
    width: 145px;
  }
  @media (max-width:600px){
    left: -4px;
  }
  @media (min-width:1000px){
    height: 220px;
    width: 75%;
  }
`
const ImgMain1 = styled.img`
    border-radius: 0px 35px 35px 0px;
    width: 100%;
    height: 226px;
    animation-name: ${animation2};
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    animation-timing-function: linear;
    @media (max-width:1000px){
        display:none;
    }
    @media (min-width:1000px){
        transform: scale(1.3);
        width: 131px;
        transform: scale(1.3);
        height: 290px;
    }
    @media (min-width:1500px){
        height: 350px;
        width: 150px;
    }
    @media (min-width:1600px){
        height: 380px;
        // width: 45%;
    }
    @media (min-width:2100px){
        height: 400px;
    }
`;
const SectionContainBg = styled.section`
    max-width:500px;
    @media (max-width:500px){
        max-width:300px;
    }
    @media (min-width:1000px){
        flex: initial;
        width:500px; 
    }
    @media (min-width:1200px){
        width:600px; 
    }
`
const ContantTextBg = styled.div`
line-height: inherit;
    h4,h5{
        line-height: inherit;
    }
    @media (min-width:1000px){
        width:500px; 
        h1{
            font-size: 47px !important;
        }
        h5{
            font-size: 14px !important;
        }
    }
    @media (min-width:1200px){
        width:600px; 
        margin-left: -4%;
        h1{
            font-size: 57px !important;
        }
        h5{
            max-width: 85%;
            margin: auto;
        }
    }
    @media (min-width:1700px){
        h1{
            font-size: xxx-large !important;
        }
    }
    @media (min-width:1800px){
        h1{
            font-size: 52px !important;
        }
    }
`
const H4Section = styled.h4`
    @media (max-width:900px){
        font-size: 17px !important;
        margin-bottom: 20px !important;
    }
    @media (min-width:1000px){
        margin-bottom: 20px !important;
        font-size: 20px !important;
    }
`
const H5Section = styled.h5`
    @media (max-width:900px){
        ont-size: 18px !important;
        margin-top: 20px !important;
    }
    @media (max-width:400px){
        font-size: 15px !important;
    }
    @media (min-width:1000px){
        font-size: 20px !important;
        margin-top: 20px !important;
    }
`
const ContainSectionImage2 = styled.section`
    position: relative;
    width: 100%;
    right: -1%;
    @media (max-width:1000px){
        display:none;
    }
    @media (min-width:1099px){
        right: -5%;
    }
    @media (min-width:1170px){
        right: -6%;
    }
    @media (min-width:1300px){
        right: -7%;
    }
    @media (min-width:1400px){
        right: -9%;
    }
    @media (min-width:1500px){
        right: -13%;
    }
    @media (min-width:1600px){
        right: -14%;
    }
    @media (min-width:1700px){
        right: -15%;
    }
    @media (min-width:2000px){
        right: -17%;
    }
`;
const ContainImg2Main = styled.div`
  position: relative;
  width: 300px;
  overflow-y: hidden;
  height: 101%;
  z-index: 65;
  @media (max-width:700px){
    height: 107%;
  }
  @media (min-width:800px){
    height: 104%;
  }
`;
const Img2Main = styled.img`
  z-index: 2;
  width: 100%;
  height: 139px;
  position: absolute;
  right: 0px;
  bottom: 3px;
  @media (max-width:400px){
    right: -10px;
    width: 200px;
  }
  @media (min-width:1000px){
    height: 190px;
  }
`
const ImgMain2 = styled.img`
  border-radius: 35px 0px 0px 35px;
  margin-right: -120px;
  height: 270px;
  animation-name: ${animation1};
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-direction: alternate;
  animation-timing-function: linear;
  @media (max-width:1000px){
    display:none;
  }
  @media (min-width:1100px){
    width: 131px;
  }
  @media (min-width:1500px){
    height: 300px;
    width: 150px;
    margin-right: 0px; 
  }
  @media (min-width:2100px){
    height: 400px;
  }
`;

export default Maintaining;
