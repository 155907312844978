import React from "react";
import'./fonts/Sansation_Bold.ttf';
import'./fonts/Sansation_Light.ttf';
import'./fonts/Sansation_Regular.ttf';
import'./fonts/Sansation_Regular.ttf';
import ReactDOM from "react-dom";
import "./index.css";
import "./../node_modules/sweetalert2/dist/sweetalert2.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
