import { put, call, takeLatest, select } from "redux-saga/effects";
import * as types from "./types";
// eslint-disable-next-line import/no-cycle
import API from "../../api";
import { profileSelector } from "./selectors";

export function* profileCall({ id, payload, code, callback }) {
  try {
    const result = yield call(API.put, `/users/${id}${code}`, payload);
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: types.PUT_PROFILE_COMPLETE,
        payload: result.data,
      });
      const success = { bool: true };
      callback(success);
    } else {
      yield put({ type: types.PUT_PROFILE_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.info
    ) {
      callback({
        bool: false,
        message: error.response.data.info[0],
      });
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.violations
    ) {
      callback({
        bool: false,
        message: [error.response.data.violations[0].message],
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.PUT_PROFILE_ERROR });
  }
}

export function* getProfileCall() {
  try {
    const users = yield select(profileSelector);
    const result = yield call(API.get, `/users/${users.id}`);
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: types.PUT_PROFILE_COMPLETE,
        payload: result.data,
      });
    } else {
      yield put({ type: types.PUT_PROFILE_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      yield put({ type: types.PUT_PROFILE_ERROR });
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.info
    ) {
      yield put({ type: types.PUT_PROFILE_ERROR });
    } else {
      yield put({ type: types.PUT_PROFILE_ERROR });
    }
    yield put({ type: types.PUT_PROFILE_ERROR });
  }
}

export function* putPasswordCall({ payload, code, callback }) {
  try {
    const result = yield call(API.post, `/change-passwords${code}`, payload);
    if (result.status === 200 || result.status === 201) {
      yield put({
        type: types.PUT_PASSWORD_COMPLETE,
      });
      const success = { bool: true };
      callback(success);
    } else {
      yield put({ type: types.PUT_PASSWORD_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.info
    ) {
      callback({
        bool: false,
        message: error.response.data.info[0],
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.PUT_PASSWORD_ERROR });
  }
}

export default function* profile() {
  yield takeLatest(types.PUT_PROFILE_INIT, profileCall);
  yield takeLatest(types.PUT_PASSWORD_INIT, putPasswordCall);
  yield takeLatest(types.GET_PROFILE_INIT, getProfileCall);
}
