import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const FooterPublic = () => {
  return (
    <div style={{  backgroundColor: "#070707"}}>
      <div>
        <ContainerLinks>
            <ContainerInformation>
              <Logo src="/assets/images/Logo_negativo.svg" alt="logo zeroenergy" />
              <br /><br /><br />
              <p>La Plataforma de Tokenización que da sentido ecológico a la tecnología blockchain.</p>
              <br />
              <ContainerItems>
                <Link to="/privacy">
                  <Items> <strong>Privacidad</strong></Items>
                </Link>
                <Link to="/cookies">
                  <Items><strong>Cookies</strong></Items>
                </Link>
                <Link to="/terms-and-conditions">
                  <Items><strong>Términos y Condiciones</strong></Items>
                </Link>
              </ContainerItems>
          </ContainerInformation>
          <Images>
              <img src="/assets/images/7b6aa9aebfdd13e9b5092e698170f2a5.png" 
              alt="images" 
              height="70px" style={{marginRight: "10px"}} />
              <img
                src="/assets/images/64639ea0e03d9fec5d9ed18bb58fa395.png"
                alt="images"
                height="70px" style={{marginRight: "10px"}}
                />
              <img
                src="/assets/images/1ecd0754d9d9e80e6029f9011a4c38d3.png"
                alt="images"
                height="70px" style={{marginRight: "10px"}}
              />
          </Images>
        </ContainerLinks>
        <Hr />
        <DivContainerNetworksAndPolitic>
          <Networks>
              <a
                href="https://www.instagram.com/zeroenergyoficial/"
                target="_blank"
                rel="noopener noreferrer"
                >
                <img
                  src="/assets/images/instagram.svg"
                  alt="instagram"
                  height="35px"
                  />
              </a>

              <a
                href="https://www.youtube.com/@zeroenergyoficial"
                target="_blank"
                rel="noopener noreferrer"
                >
                <img src="/assets/images/youtube.svg" alt="youtube" height="35px" />
              </a>


              <a
                href="https://t.me/zeroenergyoficial"
                target="_blank"
                rel="noopener noreferrer"
                >
                <img
                  src="/assets/images/telegram.svg"
                  alt="telegram"
                  height="35px"
                  />
              </a>

            </Networks>
          <EndStrip>
            <p>
            © Copyright - 2024 ZEROENERGY.PRO - TODOS LOS DERECHOS RESERVADOS
            </p>
          </EndStrip>
        </DivContainerNetworksAndPolitic>
      </div>
    </div>
  );
};
const Hr = styled.hr`
  width:90%;
  @media (min-width:1000px){
    width: 88%;
  }
`
const ContainerLinks = styled.div`
  width: 100%;
  min-height: 200px;
  background-color: #070707;
  align-items: center;
  padding-bottom: 30px;
  justify-content: space-around;
  padding: 30px 15px 30px 17px;
  @media (min-width: 620px) {
    display: flex !important;
  }
  @media (min-width:1000px){
    justify-content: space-between;
    margin: auto;

    width: 90%;

  }
`;
const Logo = styled.img`
  width: auto;
  height: 30px;
  
  object-fit: contain;
  margin-top: 30px;
  @media (min-width:1000px){
    height: 20px;
  }
`;
const Images = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width:900px){
    max-width: 235px;
  }
  @media (min-width:800px){
      img{ height: 60px;}
  }
`;
const Items = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;
const ContainerInformation = styled.div`
  width: 330px;
  color:#ffffff;
  @media (max-width:500px){
    width: 300px;
  }
`;
const EndStrip = styled.div`
  width: 100%;
  min-height: 100px;
  background-color: #070707;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    width: 95vw;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  @media (min-width:1000px){
    width: max-content !important;

    p{
      width: max-content !important;
    }
  }
`;
const ContainerItems = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  @media (max-width:900px){
    display: block !important;
  }
  @media (max-width: 420px) {
    display:block;
    justify-content:start:
    flex-direction: column;
  }

`;
const Networks = styled.div`
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding-top: 30px;
  background-color: #070707;
  @media (min-width:1000px){
    margin: 0;
    padding-top: 0;

  }
`;
const DivContainerNetworksAndPolitic = styled.div`
  @media (min-width:1000px){
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
    width: 88%;
    margin: auto;
  }
`;
export default FooterPublic;
