// import moment from "moment";
import * as types from "./types";

const initialState = {};

export default function generalStore(state = initialState, action) {
  switch (action.type) {
    case types.POST1_CONTACT_INIT:
      return {
        ...state,
        isLoggingInContact: true,
      };
    case types.POST1_CONTACT_COMPLETE:
      return {
        ...state,
        isLoggingInContact: false,
      };
    case types.POST1_CONTACT_ERROR:
      return {
        ...state,
        isLoggingInContact: false,
      };
    case types.POST_CONTACT_INIT:
      return {
        ...state,
        isLoggingIn: true,
      };
    case types.POST_CONTACT_COMPLETE:
      return {
        ...state,
        isLoggingIn: false,
      };
    case types.POST_CONTACT_ERROR:
      return {
        ...state,
        isLoggingIn: false,
      };
    case types.GET_DOCUMENT_INIT:
      return {
        ...state,
        isLoggingInDocument: true,
      };
    case types.GET_DOCUMENT_COMPLETE:
      return {
        ...state,
        isLoggingInDocument: false,
        document: action.payload,
      };
    case types.GET_DOCUMENT_ERROR:
      return {
        ...state,
        isLoggingInDocument: false,
      };
    case types.GET_MOVEMENTS_INIT:
      return {
        ...state,
        isLoggingInMovements: true,
      };
    case types.GET_OPERATIONS_INIT:
      return {
        ...state,
        isLoggingInOperations: true,
      };
    case types.GET_OPERATIONS_COMPLETE:
      return {
        ...state,
        isLoggingInOperations: false,
        operations: action.payload,
      };
    case types.GET_OPERATIONS_ERROR:
      return {
        ...state,
        isLoggingInOperations: false,
      };
    case types.GET_MOVEMENTS_COMPLETE:
      return {
        ...state,
        isLoggingInMovements: false,
        movements: action.payload,
      };
    case types.GET_MOVEMENTS_ERROR:
      return {
        ...state,
        isLoggingInMovements: false,
      };
    case types.GET_REFERRED_INIT:
      return {
        ...state,
        isLoggingInReferrals: true,
      };
    case types.GET_REFERRED_COMPLETE:
      return {
        ...state,
        isLoggingInReferrals: false,
        referrals: action.payload,
      };
    case types.GET_REFERRED_ERROR:
      return {
        ...state,
        isLoggingInReferrals: false,
      };
    case types.GET_REFERRED_LEVEL_INIT:
      return {
        ...state,
        isLoggingInReferralMyLevel: true,
      };
    case types.GET_REFERRED_LEVEL_COMPLETE:
      return {
        ...state,
        isLoggingInReferralMyLevel: false,
        referralMyLevel: action.payload,
      };
    case types.GET_REFERRED_LEVEL_ERROR:
      return {
        ...state,
        isLoggingInReferralMyLevel: false,
      };
    case types.GET_REQUIREMENTS_LEVEL_INIT:
      return {
        ...state,
        isLoggingInRequirementsLevel: true,
      };
    case types.GET_REQUIREMENTS_LEVEL_COMPLETE:
      return {
        ...state,
        isLoggingInRequirementsLevel: false,
        requirementsLevel: action.payload,
      };
    case types.GET_REQUIREMENTS_LEVEL_ERROR:
      return {
        ...state,
        isLoggingInRequirementsLevel: false,
      };
    case types.GET_REFERRED_LEVEL_1:
      return {
        ...state,
        referralLevel1: action.payload,
      };
    case types.GET_REFERRED_LEVEL_2:
      return {
        ...state,
        referralLevel2: action.payload,
      };
    case types.GET_REFERRED_LEVEL_3:
      return {
        ...state,
        referralLevel3: action.payload,
      };
    case types.GET_REFERRED_LEVEL_4:
      return {
        ...state,
        referralLevel4: action.payload,
      };
    case types.GET_REFERRED_LEVEL_5:
      return {
        ...state,
        referralLevel5: action.payload,
      };
    case types.GET_REFERRED_LEVEL_6:
      return {
        ...state,
        referralLevel6: action.payload,
      };
    case types.GET_REFERRED_LEVEL_7:
      return {
        ...state,
        referralLevel7: action.payload,
      };
    case types.GET_REFERRED_LEVEL_8:
      return {
        ...state,
        referralLevel8: action.payload,
      };
    case types.GET_REFERRED_LEVEL_9:
      return {
        ...state,
        referralLevel9: action.payload,
      };
    case types.GET_REFERRED_LEVEL_10:
      return {
        ...state,
        referralLevel10: action.payload,
      };
    case types.DEPOSIT_COIN_BASE_INIT:
      return {
        ...state,
        isLoadingDeposit: true,
      };
    case types.DEPOSIT_COIN_BASE_COMPLETE:
      return {
        ...state,
        isLoadingDeposit: false,
      };
    case types.DEPOSIT_COIN_BASE_ERROR:
      return {
        ...state,
        isLoadingDeposit: false,
      };
    case types.RETIRE_BALANCE_INIT:
      return {
        ...state,
        isLoadingRetire: true,
      };
    case types.RETIRE_BALANCE_COMPLETE:
      return {
        ...state,
        isLoadingRetire: false,
      };
    case types.RETIRE_BALANCE_ERROR:
      return {
        ...state,
        isLoadingRetire: false,
      };
    case types.GET_RETIRE_AVAILABEL_INIT:
      return {
        ...state,
        isLoadingRetireAvailabel: true,
        retireAvailabel: [],
      };
    case types.GET_RETIRE_AVAILABEL_COMPLETE:
      return {
        ...state,
        isLoadingRetireAvailabel: false,
        retireAvailabel: action.payload,
      };
    case types.GET_RETIRE_AVAILABEL_ERROR:
      return {
        ...state,
        isLoadingRetireAvailabel: false,
        retireAvailabel: [],
      };
    default:
      return state;
  }
}
