/* eslint-disable no-underscore-dangle */
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import configureStore from "./ducks";
// eslint-disable-next-line import/no-cycle
import rootSaga from "./ducks/sagas";
import Routes from "./template";
import SweetAlert from "./components/SweetAlert"
const { store, persistor, sagaMiddleware } = configureStore();
sagaMiddleware.run(rootSaga);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Routes />
        <SweetAlert />
      </PersistGate>
    </Provider>
  );
}

export { store };

export default App;
