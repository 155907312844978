import React, { useState } from "react";
import styled from "styled-components";

const Collapse = ({ title, description,id }) => {
  const [open, setOpen] = useState(false);


  return (
    <div className="pb-5" style={{marginBottom: "0", backgroundColor:"white", borderBottom: "inset"}} >
      <div className="card-header " id={"Heade"+id} style={{backgroundColor:"white"}} onClick={() => setOpen(!open)}>
        <h5 className="mb-0 w-100">
          <button  className="btn btn-link collapsed w-100 p-0" style={{textDecoration:"none"}} data-toggle="collapse" data-target={"#"+id} aria-expanded="true" aria-controls={id} >
            <li className="list-group-item d-flex justify-content-between align-items-center border-0" style={{backgroundColor:"white"}}>
            <h4 className="text-left txt-h4 w-100" style={{whiteSpace: "normal"}}> {title} </h4>
            {
              open
              ? 
                <img src="/assets/images/Iconwrapmenos.png" alt="logo zeroenergy" style={{width:"40px"}} />
              :          
                <img src="/assets/images/IconMas.png" alt="logo zeroenergy" style={{width:"37px"}} />
            }
            </li>
          </button>
        </h5>
      </div>
      { open &&
        <div id={id} className="collapse" aria-labelledby={id} data-parent="#accordion" style={{padding: "0.5rem 1.5rem"}}>
        <div className="card-body text-left pt-0">
          <TextGeneral className="txt-p-1" style={{fontFamily:"'Roboto'",color:"#475467"}}>
            {description}
          </TextGeneral>
        </div>
      </div>
      }

    </div>
  );
};
const SpanPlus = styled.span`
  border: solid 1px;
  padding: 10px 15px 10px 15px;
  border-radius: 70px;
  font-size: 20px;
`;
const TextGeneral = styled.p`
  @media (min-width:1800px){
    font-size: 23px !important;
  }
  @media (min-width:2100px){
    font-size: 28px !important;
  }
`
export default Collapse;
