export const isLoggingInSelector = (state) => state.generalStore.isLoggingIn;
export const isLoggingDocumentInSelector = (state) =>
  state.generalStore.isLoggingInDocument;
export const documentInSelector = (state) => state.generalStore.document;
export const referralsInSelector = (state) => state.generalStore.referrals;
export const requirementsLevelInSelector = (state) =>
  state.generalStore.requirementsLevel;
export const referralIsLoggingInSelector = (state) =>
  state.generalStore.isLoggingInReferrals;
export const movementsInSelector = (state) => state.generalStore.movements;
export const referralLevel1Selector = (state) =>
  state.generalStore.referralLevel1;
export const referralLevel2Selector = (state) =>
  state.generalStore.referralLevel2;
export const referralLevel3Selector = (state) =>
  state.generalStore.referralLevel3;
export const referralLevel4Selector = (state) =>
  state.generalStore.referralLevel4;
export const referralLevel5Selector = (state) =>
  state.generalStore.referralLevel5;
export const referralLevel6Selector = (state) =>
  state.generalStore.referralLevel6;
export const referralLevel7Selector = (state) =>
  state.generalStore.referralLevel7;
export const referralLevel8Selector = (state) =>
  state.generalStore.referralLevel8;
export const referralLevel9Selector = (state) =>
  state.generalStore.referralLevel9;
export const referralLevel10Selector = (state) =>
  state.generalStore.referralLevel10;
export const referralMyLevelSelector = (state) =>
  state.generalStore.referralMyLevel;
export const isLoggingInRequirementsLevelSelector = (state) =>
  state.generalStore.isLoggingInRequirementsLevel;
export const isLoggingInReferralMyLevelSelector = (state) =>
  state.generalStore.isLoggingInReferralMyLevel;
export const isLoggingInMovementsSelector = (state) =>
  state.generalStore.isLoggingInMovements;
export const operationsSelector = (state) => state.generalStore.operations;
