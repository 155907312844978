import * as types from "./types";

export function postContactInit(payload, callback) {
  return {
    type: types.POST_CONTACT_INIT,
    payload,
    callback,
  };
}

/* ??? function callback for contact form on lading ? */
export function postContact1Init(payload, callback) {
  return {
    type: types.POST1_CONTACT_INIT,
    payload,
    callback,
  };
}

export function documentInit(callback) {
  return {
    type: types.GET_DOCUMENT_INIT,
    callback,
  };
}

export function operationsInit(payload, callback) {
  return {
    type: types.GET_OPERATIONS_INIT,
    payload,
    callback,
  };
}

export function movementsInit(payload, callback) {
  return {
    type: types.GET_MOVEMENTS_INIT,
    payload,
    callback,
  };
}

export function referralMyLevelInit(callback) {
  return {
    type: types.GET_REFERRED_LEVEL_INIT,
    callback,
  };
}

export function requirementsLevelInit(callback) {
  return {
    type: types.GET_REQUIREMENTS_LEVEL_INIT,
    callback,
  };
}

export function referralsInit(callback) {
  return {
    type: types.GET_REFERRED_INIT,
    callback,
  };
}

export function referralLevel1Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_1,
    payload,
  };
}

export function referralLevel2Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_2,
    payload,
  };
}

export function referralLevel3Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_3,
    payload,
  };
}

export function referralLevel4Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_4,
    payload,
  };
}

export function referralLevel5Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_5,
    payload,
  };
}

export function referralLevel6Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_6,
    payload,
  };
}

export function referralLevel7Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_7,
    payload,
  };
}

export function referralLevel8Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_8,
    payload,
  };
}

export function referralLevel9Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_9,
    payload,
  };
}

export function referralLevel10Init(payload) {
  return {
    type: types.GET_REFERRED_LEVEL_10,
    payload,
  };
}

export function depositCoinBaseInit(payload, callback) {
  return {
    type: types.DEPOSIT_COIN_BASE_INIT,
    payload,
    callback,
  };
}

export function retireBalanceInit(payload, callback) {
  return {
    type: types.RETIRE_BALANCE_INIT,
    payload,
    callback,
  };
}

export function getRetireAvailabel(callback) {
  return {
    type: types.GET_RETIRE_AVAILABEL_INIT,
    callback,
  };
}
