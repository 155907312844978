import * as types from "./types";

const initialState = {};

export default function graphicsStore(state = initialState, action) {
  switch (action.type) {
    case types.GET_PERFORMANCE_INIT:
      return {
        ...state,
        isLoggingIn: true,
      };
    case types.GET_PERFORMANCE_COMPLETE:
      return {
        ...state,
        isLoggingIn: false,
        graphicPlatformPerformance: action.payload,
      };
    case types.GET_PERFORMANCE_ERROR:
      return {
        ...state,
        isLoggingIn: false,
      };
    case types.GET_DASHBOARD_INIT:
      return {
        ...state,
        isLoggingDashboardIn: true,
      };
    case types.GET_DASHBOARD_COMPLETE:
      return {
        ...state,
        isLoggingDashboardIn: false,
        performanceLast30Days: action.performanceLast30DaysUsers,
        infoTotal: action.infoTotal,
        amountInvestment: action.amountInvestment,
        percentagePlatform: action.percentagePlatform,
        percentageRefered: action.percentageRefered,
        referraData: action.referraData,
        chartBar: action.chartBar,
      };
    case types.GET_DASHBOARD_ERROR:
      return {
        ...state,
        isLoggingDashboardIn: false,
      };
    default:
      return state;
  }
}
