export const POST_CONTACT_INIT = "POST_CONTACT_INIT";
export const POST_CONTACT_COMPLETE = "POST_CONTACT_COMPLETE";
export const POST_CONTACT_ERROR = "POST_CONTACT_ERROR";

export const POST1_CONTACT_INIT = "POST1_CONTACT_INIT";
export const POST1_CONTACT_COMPLETE = "POST1_CONTACT_COMPLETE";
export const POST1_CONTACT_ERROR = "POST1_CONTACT_ERROR";

export const GET_DOCUMENT_INIT = "GET_DOCUMENT_INIT";
export const GET_DOCUMENT_COMPLETE = "GET_DOCUMENT_COMPLETE";
export const GET_DOCUMENT_ERROR = "GET_DOCUMENT_ERROR";

export const GET_OPERATIONS_INIT = "GET_OPERATIONS_INIT";
export const GET_OPERATIONS_COMPLETE = "GET_OPERATIONS_COMPLETE";
export const GET_OPERATIONS_ERROR = "GET_OPERATIONS_ERROR";

export const GET_MOVEMENTS_INIT = "GET_MOVEMENTS_INIT";
export const GET_MOVEMENTS_COMPLETE = "GET_MOVEMENTS_COMPLETE";
export const GET_MOVEMENTS_ERROR = "GET_MOVEMENTS_ERROR";

export const GET_REFERRED_INIT = "GET_REFERRED_INIT";
export const GET_REFERRED_COMPLETE = "GET_REFERRED_COMPLETE";
export const GET_REFERRED_ERROR = "GET_REFERRED_ERROR";

export const GET_REFERRED_LEVEL_INIT = "GET_REFERRED_LEVEL_INIT";
export const GET_REFERRED_LEVEL_COMPLETE = "GET_REFERRED_LEVEL_COMPLETE";
export const GET_REFERRED_LEVEL_ERROR = "GET_REFERRED_LEVEL_ERROR";

export const GET_REQUIREMENTS_LEVEL_INIT = "GET_REQUIREMENTS_LEVEL_INIT";
export const GET_REQUIREMENTS_LEVEL_COMPLETE =
  "GET_REQUIREMENTS_LEVEL_COMPLETE";
export const GET_REQUIREMENTS_LEVEL_ERROR = "GET_REQUIREMENTS_LEVEL_ERROR";

export const GET_REFERRED_LEVEL_1 = "GET_REFERRED_LEVEL_1";
export const GET_REFERRED_LEVEL_2 = "GET_REFERRED_LEVEL_2";
export const GET_REFERRED_LEVEL_3 = "GET_REFERRED_LEVEL_3";
export const GET_REFERRED_LEVEL_4 = "GET_REFERRED_LEVEL_4";
export const GET_REFERRED_LEVEL_5 = "GET_REFERRED_LEVEL_5";
export const GET_REFERRED_LEVEL_6 = "GET_REFERRED_LEVEL_6";
export const GET_REFERRED_LEVEL_7 = "GET_REFERRED_LEVEL_7";
export const GET_REFERRED_LEVEL_8 = "GET_REFERRED_LEVEL_8";
export const GET_REFERRED_LEVEL_9 = "GET_REFERRED_LEVEL_9";
export const GET_REFERRED_LEVEL_10 = "GET_REFERRED_LEVEL_10";

export const DEPOSIT_COIN_BASE_INIT = "DEPOSIT_COIN_BASE_INIT"
export const DEPOSIT_COIN_BASE_COMPLETE = "DEPOSIT_COIN_BASE_COMPLETE"
export const DEPOSIT_COIN_BASE_ERROR = "DEPOSIT_COIN_BASE_ERROR"

export const RETIRE_BALANCE_INIT = "RETIRE_BALANCE_INIT"
export const RETIRE_BALANCE_COMPLETE = "RETIRE_BALANCE_COMPLETE"
export const RETIRE_BALANCE_ERROR = "RETIRE_BALANCE_ERROR"

export const GET_RETIRE_AVAILABEL_INIT = "GET_RETIRE_AVAILABEL_INIT"
export const GET_RETIRE_AVAILABEL_COMPLETE = "GET_RETIRE_AVAILABEL_COMPLETE"
export const GET_RETIRE_AVAILABEL_ERROR = "GET_RETIRE_AVAILABEL_ERROR"