import React, { useEffect } from "react";

import styled from "styled-components";
import FooterPublic from "../components/layout/FooterPublic";
import HeaderPublic from "../components/layout/HeaderPublic";

const Cookies = (props) => {
  useEffect(() => {
    window.scroll(0, 0);
  });
  return (
    <div>
      <HeaderPublic history={props.history} />
      <ContainImage>
          <p> POLITICA DE COOKIES </p>
        </ContainImage>
      <div className="total-center bg-white">
        <Text>
          ¿QUÉ SON LAS COOKIES?
          <br />
          Las cookies son los elementos utilizados por las páginas web cuyo
          objeto es optimizar la experiencia de navegación de los usuarios 
          consistente en crear en el ordenador, tableta, teléfono inteligente 
          o dispositivo equivalente con funciones de navegación a través de 
          Internet, un fichero informático con información de sus preferencias, 
          gustos y perfil, permitiendo personalizar los servicios del titular de 
          la web, con el fin de que en la próxima visita los contenidos y 
          funcionalidades se ajusten a sus preferencias, posibilitando la 
          reproducción y visualización de contenido multimedia en la propia web, 
          permitiendo elementos de interactuación entre el usuario y la web o habilitar 
          herramientas de seguridad.  <br />
          
          El usuario del portal web podrá elegir la aceptación de la totalidad de las 
          cookies, o de alguna de ellas, o bien, rechazar el uso de cookies en el portal 
          web. Por lo tanto, usted puede permitir, bloquear o eliminar las cookies instaladas, 
          mediante la selección de la configuración apropiada de su navegador. 
          En el caso de que usted decidiera rechazar las cookies o alguna de ellas, debe saber 
          que no va a disfrutar de la plena funcionalidad de este portal web, y por tanto, 
          Zero-Energy no le garantiza al usuario, el acceso a todo el contenido del 
          portal web https://zeroenergy.pro
          <br />
          Zero-Energy no enlazará en las cookies los datos memorizados con sus datos personales 
          proporcionados en el momento del registro o la compra, sin el expreso deseo de los usuarios, 
          que podrán prestarlo a través de la activación de las cookies en su navegador. 
          <br />
          <br />
          TIPOS DE COOKIES
          <br />
          <br />
          Existen distintos tipos de cookies que se clasifican como se expone a continuación: 
          - No exceptuadas: referidas al resto de cookies. 
          - Exceptuadas: Cookies de entrada de usuario, Cookies de identificación o autenticación del usuario. 
          (únicamente de sesión), Cookies de seguridad del usuario, Cookies de reproductor multimedia, Cookies 
          de sesión para equilibrar la carga, Cookies de personalización de la interfaz de usuario y Cookies de 
          complemento (plug-in) para intercambiar contenidos sociales. 
          <br />
          <br />
          -  Cookies según el tiempo que permanecen activadas: 
          -  Cookies según finalidad: 
          -  Cookies según entidad que las gestione: 
          <br />
          <br />
          Cookies propias: Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio 
          gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario. 
          <br />
          Cookies de tercero: Son aquellas que se envían al equipo terminal del usuario desde un equipo o 
          dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos a 
          través de las cookies.
          <br />
          Cookies de sesión: Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. 
          Cookies persistentes: Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser 
          accedidos y tratados durante un período definido por el responsable de la cookie y que puede ir de unos minutos a 
          varios años. 
          <br />
          Cookies de análisis: Son aquellas que, bien tratadas por el titular del sitio,
          o por terceros, permiten cuantificar el número de usuarios y así realizar la
          medición y análisis estadístico de la utilización, que hacen los usuarios del
          servicio ofertado. Para ello, se analiza su navegación en la página web, con
          el fin de mejorar la oferta de productos o servicios que le ofrecemos. 
          Cookies publicitarias: Son aquellas que permiten la gestión, de la forma 
          más eficaz posible, de los espacios publicitarios que, en su caso, el 
          editor haya incluido en una página web, aplicación o plataforma desde 
          la que presta el servicio solicitado en base a criterios como el contenido editado 
          o la frecuencia en la que se muestran los anuncios. 
          <br />
          Cookies sociales son necesarias para las redes sociales externas 
          (Facebook, Google, Twitter, Pinterest, Instagram, etc...). 
          Su función es controlar la interacción con los widgets 
          sociales dentro de la página. 
          <br />
          Cookies de publicidad comportamental: Son aquellas que permiten la gestión, 
          de la forma más eficaz posible, de los espacios publicitarios que, en su caso, 
          el editor haya incluido en una página web, aplicación o plataforma desde la que 
          presta el servicio solicitado. Estas cookies almacenan información del comportamiento 
          de usuarios obtenida a través de la observación continuada de sus hábitos de navegación, 
          lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo. 
          También es posible que al visitar alguna página web o al abrir algún email donde se publique 
          algún anuncio o alguna promoción sobre determinados productos o servicios se instale en el 
          navegador alguna cookie que se utiliza para mostrar posteriormente publicidad relacionada 
          con la búsqueda que se haya realizado, para desarrollar un control de los anuncios en relación, 
          por ejemplo, con el número de veces que son vistos, donde aparecen, a qué hora se ven, etc. 
          <br />
          A continuación, se indica que Cookies no excluidas, propias, o de terceros, se utilizan en el 
          sitio web:  (Hemos expuesto las cookies más habituales. No obstante hay que especificar en 
          este apartado, cuáles son las Cookies que integra Zero-Energy en su website). 
          <br />
          <br />
          - Google Analytics: (Cookies analíticas de terceros). -
          <br />
          <br />- Google, Inc., 1600 Amphitheatre Parkway, Mountain View
          (California), CA 94043, Estados Unidos ("Google"). Google Analytics
          utiliza "cookies", que son archivos de texto ubicados en su ordenador,
          para ayudar al website a analizar el uso que hacen los usuarios del
          sitio web. La información que genera la cookie acerca de su uso del
          website (incluyendo su dirección IP) será directamente transmitida y
          archivada por Google en los servidores de Estados Unidos. Google usará
          esta información, por cuenta nuestra, con el propósito de seguir la
          pista de su uso del website, recopilando informes de la actividad del
          website y prestando otros servicios relacionados con la actividad del
          website y el uso de Internet. Google podrá transmitir dicha
          información a terceros cuando así se lo requiera la legislación, o
          cuando dichos terceros procesen la información por cuenta de Google.{" "}
          <br />
          Google no asociará su dirección IP con ningún otro dato del que
          disponga Google.
          <br />
          <br />
          Al utilizar este website usted consiente el tratamiento de información
          acerca de usted
          <br />
          por Google en la forma y para los fines arriba indicados. En todo
          caso, le informamos que para instar a la cancelación de los posibles
          tratamientos de datos llevados a cabo por Google, deberá dirigirse a
          esa compañía, a tal efecto. El prestador no tiene la capacidad técnica
          ni legal, para proceder al cese en el tratamiento de datos fuera del
          ámbito delimitado por los ficheros y medios técnicos de su
          titularidad. Y no se le podrán exigir responsabilidades en este
          sentido.
          <br />
          Más información en: www.google.es/intl/es/policies/technologies/types/
          <br />
          https://developers.google.com/analytics/devguides/collection/analyticsjs/cookies-usage.
          <br />
          Otros servicios prestados por Google, Inc., en el sitio web, que
          implican la utilización de cookies:
          <br />
          - - Google AdWords: (Cookies publicitarias de terceros).
          <br />
          Servicios de publicidad patrocinada online prestados por Google Inc.
          Las cookies son utilizadas por Google para la gestión de los referidos
          servicios.
          <br />
          <br />
          ¿QUÉ DATOS SE RECABAN A TRAVÉS DE LAS COOKIES?
          <br />
          Información más detallada: - Google Chrome:
          https://support.google.com/chrome/answer/95647?hl=es - - Internet
          Explorer: http://support.microsoft.com/kb/196955/es - Safari:
          http://support.apple.com/kb/ph5042 - - <br />
          NORMATIVA APLICABLE El uso de las cookies y las obligaciones derivadas
          de la aplicación de las mismas en los portales web, se encuentra
          regulado en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de
          Servicios de la Sociedad de la Información y de Comercio Electrónico
          (LSSI), en relación con el Reglamento General de Protección de Datos
          (RGPD) y la Ley Orgánica 3/2018 de Protección de Datos y garantía de
          los derechos digitales (LOPDGDD).
          <br />
          Las cookies podrán recabar los siguientes datos: número IP, historial
          de búsqueda, localización, ID de dispositivo, número de teléfono,
          visionados de publicidad, analíticos, información del navegador, datos
          de cookies, fecha y hora, datos demográficos, información del
          software/hardware, información acerca de interacciones, páginas
          visitadas, dominios.
          <br />
          Zero-Energy le ofrece los enlaces en los que encontrará información
          sobre cómo puede configurar sus preferencias, respecto a las cookies,
          en los principales
          <br />
          navegadores:
          <br />
          Mozilla Firefox:
          http://support.mozilla.org/es/kb/cookies-informacion-que-los-
          sitios-web-guardan-en-?redirectlocale=en-US&redirectslug=Cookies
          Safari IOS (iPhone, iPad):
          http://support.apple.com/kb/HT1677?viewlocale=es_ES&locale=es_ES
          Cookies Flash:
          http://www.macromedia.com/support/documentation/es/flashplayer/help/set
          tings_manager07.html
          <br />
          Si lo prefiere, puede consultar los vídeos tutoriales publicados por
          la Agencia Española de Protección de Datos:
          http://www.youtube.com/user/desdelaAEPD 
          <br />          
          TRADUCCIÓN A OTROS IDIOMAS
          <br />
          Toda la información y documentación disponible en las versiones de
          otros idiomas, de la presente página web, se proporciona únicamente a
          efectos informativos. En caso de discrepancia entre el contenido de la
          versión de lengua extranjera y el de la versión original en español
          prevalecerá el de esta última.
          <br />
          Si lo desea, puede consultar la Guía sobre el Uso de las Cookies,
          elaborado por la Agencia
          <br />
          Estatal de Protección de datos, a través del siguiente link:
          https://www.aepd.es/sites/default/files/2019-12/guia-cookies_1.pdf
        </Text>
      </div>
      <FooterPublic />
    </div>
  );
};

const Text = styled.p`
  margin-top: 30px;
  width: 95vw;
  max-width: 1200px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: left;
  color: #1d2b45;
  span {
    font-weight: bold;
  }
`;
const ContainImage = styled.div`
  background-image: url("assets/images/3025f436ae9040a103882f220b864cd4.jpeg");
  height:400px;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size:30px
  color:white;
  background-position-y: center;
  @media (max-width: 768px) {
    font-size:28px
  }
`;

export default Cookies;
