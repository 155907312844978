import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import accessStore from "./access";
import profileStore from "./profile";
import generalStore from "./general";
import graphicsStore from "./graphics";

const reducers = combineReducers({
  accessStore,
  profileStore,
  generalStore,
  graphicsStore,
});

const persistConfig = {
  key: "karakorumApp",
  storage,
};

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware();
export default function configureStore() {
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(sagaMiddleware))
  );
  const persistor = persistStore(store);
  return { store, persistor, sagaMiddleware };
}
