export const PUT_PROFILE_INIT = "PUT_PROFILE_INIT";
export const PUT_PROFILE_COMPLETE = "PUT_PROFILE_COMPLETE";
export const PUT_PROFILE_ERROR = "PUT_PROFILE_ERROR";

export const GET_PROFILE_INIT = "GET_PROFILE_INIT";
export const GET_PROFILE_COMPLETE = "GET_PROFILE_COMPLETE";
export const GET_PROFILE_ERROR = "GET_PROFILE_ERROR";

export const PUT_PASSWORD_INIT = "PUT_PASSWORD_INIT";
export const PUT_PASSWORD_COMPLETE = "PUT_PASSWORD_COMPLETE";
export const PUT_PASSWORD_ERROR = "PUT_PASSWORD_ERROR";