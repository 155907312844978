// import moment from "moment";
import { REHYDRATE } from "redux-persist/es/constants";
import * as types from "./types";

const initialState = {};

export default function accessStore(state = initialState, action) {
  let incoming;
  switch (action.type) {
    case types.LOGIN_INIT:
      return {
        ...state,
        isLoggingIn: true,
      };
    case types.LOGIN_COMPLETE:
      return {
        ...state,
        isLoggingIn: false,
        ...action.payload,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isLoggingIn: false,
      };
    case types.REGISTER_INIT:
      return {
        ...state,
        isRegistering: true,
      };
    case types.REGISTER_COMPLETE:
      return {
        ...state,
        isRegistering: false,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        isRegistering: false,
      };
    case types.RECOVERY_PASSWORD_INIT:
      return {
        ...state,
        isRecoveryPassword: true,
      };
    case types.RECOVERY_PASSWORD_COMPLETE:
      return {
        ...state,
        isRecoveryPassword: false,
      };
    case types.RECOVERY_PASSWORD_ERROR:
      return {
        ...state,
        isRecoveryPassword: false,
      };
    case types.CHANGE_PASSWORD_INIT:
      return {
        ...state,
        isChangePassword: true,
      };
    case types.CHANGE_PASSWORD_COMPLETE:
      return {
        ...state,
        isChangePassword: false,
      };
    case types.CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        isChangePassword: false,
      };
    case types.GOOGLE_AUTHENTICADOR_QR_INIT:
      return {
        ...state,
        isAuthenticadorQr: true,
      };
    case types.GOOGLE_AUTHENTICADOR_QR_COMPLETE:
      return {
        ...state,
        isAuthenticadorQr: false,
      };
    case types.GOOGLE_AUTHENTICADOR_QR_ERROR:
      return {
        ...state,
        isAuthenticadorQr: false,
      };
    case types.ACTIVE_GOOGLE_AUTHENTICADOR_QR_INIT:
      return {
        ...state,
        isActiveAuthenticadorQr: true,
      };
    case types.ACTIVE_GOOGLE_AUTHENTICADOR_QR_COMPLETE:
      return {
        ...state,
        isActiveAuthenticadorQr: false,
      };
    case types.ACTIVE_GOOGLE_AUTHENTICADOR_QR_ERROR:
      return {
        ...state,
        isActiveAuthenticadorQr: false,
      };
    case types.ENTER_CODE_GOOGLE_AUTHENTICADOR_INIT:
      return {
        ...state,
        isEnterCodeActiveAuthenticador: true,
      };
    case types.ENTER_CODE_GOOGLE_AUTHENTICADOR_COMPLETE:
      return {
        ...state,
        isEnterCodeActiveAuthenticador: false,
      };
    case types.ENTER_CODE_GOOGLE_AUTHENTICADOR_ERROR:
      return {
        ...state,
        isEnterCodeActiveAuthenticador: false,
      };
    case types.ACTIVE_ACCOUNT_INIT:
      return {
        ...state,
        isActiveAccount: true,
      };
    case types.ACTIVE_ACCOUNT_COMPLETE:
      return {
        ...state,
        isActiveAccount: false,
      };
    case types.ACTIVE_ACCOUNT_ERROR:
      return {
        ...state,
        isActiveAccount: false,
      };
    case types.LOGOUT:
      return initialState;
    case REHYDRATE:
      incoming = action.payload ? action.payload.accessStore : null;
      if (incoming) {
        return {
          ...incoming,
          isLoggingIn: false,
        };
      }
      return state;
    default:
      return state;
  }
}
