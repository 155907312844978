import { put, call, takeLatest } from "redux-saga/effects";
import moment from "moment";
import * as types from "./types";
// eslint-disable-next-line import/no-cycle
import API from "../../api";

export function* getPerformanceCall({ payload, callback }) {
  try {
    const result = yield call(
      API.get,
      `/base_performances?pagination=false&date[after]=${payload.end}&date[before]=${payload.initial}`
    );
    if (result.status >= 200 && result.status <= 299) {
      if (
        result.data &&
        Array.isArray(result.data["hydra:member"]) &&
        result.data["hydra:member"].length
      ) {
        const value = [];
        const labels = [];
        let platformPerformance = {};
        result.data["hydra:member"].forEach((data) => {
          value.push(parseFloat(data.percentage, 10));
        });
        result.data["hydra:member"].forEach((item) => {
          labels.push(moment(`${item.date}`).zone(-120).format("DD-MM-YYYY"));
        });
        platformPerformance = {
          labels,
          value,
        };
        yield put({
          type: types.GET_PERFORMANCE_COMPLETE,
          payload: platformPerformance,
        });
      } else {
        yield put({ type: types.GET_PERFORMANCE_ERROR });
      }
    } else {
      yield put({ type: types.GET_PERFORMANCE_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_PERFORMANCE_ERROR });
  }
}

export function* getDashboardCall({ payload, callback }) {
  try {
    const result = yield call(API.get, `dashboard?days=${payload}`);
    if (result.status >= 200 && result.status <= 299) {
      if (result.data && Array.isArray(result.data.performanceUser)) {
        const data = [];
        const labels = [];
        let performancesShopping = 0;
        result.data.performanceUser.forEach((item) => {
          performancesShopping += parseFloat(item.amount);
          data.push(parseFloat(item.amount));
        });
        result.data.performanceUser.forEach((item) => {
          labels.push(moment(`${item.date}`).zone(-120).format("DD-MM-YYYY"));
        });
        let amountInvestment = 0;
        if (
          Array.isArray(result.data.transactions) &&
          result.data.transactions.length
        ) {
          result.data.transactions.forEach((item) => {
            amountInvestment += parseFloat(item.amount);
          });
        }
        let percentagePlatform = 0;
        if (
          Array.isArray(result.data.performanceBase) &&
          result.data.performanceBase.length
        ) {
          result.data.performanceBase.forEach((item) => {
            percentagePlatform += parseFloat(item.percentage);
          });
        }
        const referraData = [];
        const chartBar = { labels: [], performanceUsers: [], performanceUserReferrals: [] };
        if (
          result.data &&
          Array.isArray(result.data.performanceUserAndReferral) &&
          result.data.performanceUserAndReferral.length
        ) {
          result.data.performanceUserAndReferral.forEach((item) => {
            if (item.performanceUser > 0 || item.performanceUserReferral > 0) {
              //console.log('user',item.performanceUser,'refer',item.performanceUserReferral)
              chartBar.labels.push(item.date)
              chartBar.performanceUsers.push(item.performanceUser)
              chartBar.performanceUserReferrals.push(item.performanceUserReferral)
            }
            //console.log(chartBar);
            referraData.push(item.performanceUserReferral);
          });
        }
        const performanceLast30Days = {
          data,
          labels,
          performancesShopping,
        };
        yield put({
          type: types.GET_DASHBOARD_COMPLETE,
          performanceLast30DaysUsers: performanceLast30Days,
          infoTotal: result.data,
          amountInvestment: amountInvestment,
          percentagePlatform: percentagePlatform,
          percentageRefered: result.data.performanceByRefered,
          referraData: referraData,
          chartBar,
        });
      } else {
        yield put({ type: types.GET_DASHBOARD_ERROR });
      }
    } else {
      yield put({ type: types.GET_DASHBOARD_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_DASHBOARD_ERROR });
  }
}

export default function* graphics() {
  yield takeLatest(types.GET_PERFORMANCE_INIT, getPerformanceCall);
  yield takeLatest(types.GET_DASHBOARD_INIT, getDashboardCall);
}
