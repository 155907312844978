import * as types from "./types";

export function putProfileInit(id, payload, code, callback) {
  return {
    type: types.PUT_PROFILE_INIT,
    id,
    payload,
    code,
    callback,
  };
}

export function putPasswordInit(payload, code, callback) {
  return {
    type: types.PUT_PASSWORD_INIT,
    payload,
    code,
    callback,
  };
}

export function setProfileInit(payload) {
  return {
    type: types.PUT_PROFILE_COMPLETE,
    payload,
  };
}

export function getProfileInit() {
  return {
    type: types.GET_PROFILE_INIT,
  };
}
