import { put, call, takeLatest, select } from "redux-saga/effects";
import * as types from "./types";
import { operationsSelector } from "./selectors";
// import { userSelector } from "../access/selectors";
// eslint-disable-next-line import/no-cycle
import API from "../../api";

export function* contactCall({ payload, callback }) {
  try {
    const result = yield call(API.post, `/support`, payload);
    if (result.status >= 200 && result.status <= 299) {
      yield put({ type: types.POST_CONTACT_ERROR });
      callback({ bool: true });
    } else {
      yield put({ type: types.POST_CONTACT_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.POST_CONTACT_ERROR });
  }
}

export function* contact1Call({ payload, callback }) {
  try {
    const result = yield call(API.post, `/contact`, payload);
    if (result.status >= 200 && result.status <= 299) {
      yield put({ type: types.POST1_CONTACT_COMPLETE });
      callback({ bool: true });
    } else {
      yield put({ type: types.POST1_CONTACT_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe el mensaje ha generardo error, intente mas tarde.",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.POST1_CONTACT_ERROR });
  }
}

export function* documentCall({ callback }) {
  try {
    const result = yield call(API.get, `/documents`);
    if (result.status >= 200 && result.status <= 299) {
      if (
        Array.isArray(result.data["hydra:member"]) &&
        result.data["hydra:member"].length
      ) {
        console.log('result', result)
        yield put({
          type: types.GET_DOCUMENT_COMPLETE,
          payload: result.data["hydra:member"],
        });
        callback({ bool: true });
      } else {
        yield put({ type: types.GET_DOCUMENT_ERROR });
      }
    } else {
      yield put({ type: types.GET_DOCUMENT_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_DOCUMENT_ERROR });
  }
}

export function* referralsCall({ callback }) {
  try {
    const result = yield call(API.get, `/tree`);
    if (result.status >= 200 && result.status <= 299) {
      if (Array.isArray(result.data) && result.data.length) {
        yield put({ type: types.GET_REFERRED_COMPLETE, payload: result.data });
      } else {
        yield put({ type: types.GET_REFERRED_COMPLETE, payload: [] });
      }
    } else {
      yield put({ type: types.GET_REFERRED_COMPLETE, payload: [] });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_REFERRED_ERROR });
  }
}

export function* referralMyLevelCall({ callback }) {
  try {
    const result = yield call(API.get, `/levels`);
    if (result.status >= 200 && result.status <= 299) {
      if (Array.isArray(result.data) && result.data.length) {
        yield put({
          type: types.GET_REFERRED_LEVEL_COMPLETE,
          payload: result.data,
        });
      } else {
        yield put({
          type: types.GET_REFERRED_LEVEL_COMPLETE,
          payload: [],
        });
      }
    } else {
      yield put({
        type: types.GET_REFERRED_LEVEL_COMPLETE,
        payload: [],
      });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_REFERRED_LEVEL_ERROR });
  }
}

export function* requirementsLevelCall({ callback }) {
  try {
    const result = yield call(API.get, `/referral_configs`);
    if (result.status >= 200 && result.status <= 299) {
      if (
        Array.isArray(result.data["hydra:member"]) &&
        result.data["hydra:member"].length
      ) {
        yield put({
          type: types.GET_REQUIREMENTS_LEVEL_COMPLETE,
          payload: result.data["hydra:member"],
        });
      } else {
        yield put({
          type: types.GET_REQUIREMENTS_LEVEL_COMPLETE,
          payload: [],
        });
      }
    } else {
      yield put({
        type: types.GET_REQUIREMENTS_LEVEL_COMPLETE,
        payload: [],
      });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_REQUIREMENTS_LEVEL_ERROR });
  }
}

export function* movementsCall({ payload, callback }) {
  try {
    const result = yield call(
      API.get,
      `/transactions${payload}&itemsPerPage=10`
    );
    if (result.status >= 200 && result.status <= 299) {
      if (
        Array.isArray(result.data["hydra:member"]) &&
        result.data["hydra:member"].length
      ) {
        const list = {
          totalItems: result.data["hydra:totalItems"],
          list: result.data["hydra:member"],
        };
        yield put({ type: types.GET_MOVEMENTS_COMPLETE, payload: list });
      } else {
        yield put({ type: types.GET_MOVEMENTS_COMPLETE, payload: [] });
      }
    } else {
      yield put({ type: types.GET_MOVEMENTS_COMPLETE, payload: [] });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_MOVEMENTS_ERROR });
  }
}

export function* operationsCall({ payload, callback }) {
  try {
    const result = yield call(API.get, `/monitor-operations?limit=${payload}`);
    if (result.status >= 200 && result.status <= 299) {
      if (Array.isArray(result.data) && result.data.length) {
        if (result.data.length >= 40) {
          yield put({
            type: types.GET_OPERATIONS_COMPLETE,
            payload: result.data,
          });
        } else {
          const operations = yield select(operationsSelector);
          const array = JSON.stringify(operations);
          const newArray = JSON.parse(array);
          if (newArray[0].id !== result.data[0].id) {
            newArray.unshift(result.data[0]);
            newArray.pop();
            yield put({
              type: types.GET_OPERATIONS_COMPLETE,
              payload: newArray,
            });
          }
        }
      } else {
        yield put({ type: types.GET_OPERATIONS_COMPLETE, payload: [] });
      }
    } else {
      yield put({ type: types.GET_OPERATIONS_COMPLETE, payload: [] });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_OPERATIONS_ERROR });
  }
}

export function* depositCoinBaseCall({ payload, callback }) {
  try {
    const result = yield call(API.post, `/deposit`, payload);
    if (result.status >= 200 && result.status <= 299) {
      yield put({ type: types.DEPOSIT_COIN_BASE_COMPLETE });
      callback({ bool: true, data: result.data.data });
    } else {
      yield put({ type: types.DEPOSIT_COIN_BASE_COMPLETE });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.DEPOSIT_COIN_BASE_ERROR });
  }
}

export function* retireBalanceCall({ payload, callback }) {
  try {
    const result = yield call(API.post, `/whitdrawal`, payload);
    if (result.status >= 200 && result.status <= 299) {
      if (
        result &&
        result.data &&
        result.data.transaction &&
        result.data.transaction.status === "failed"
      ) {
        callback({
          bool: false,
          message:
            "Disculpe la transaccion ha fallado, comuniquese con soporte.",
        });
      } else {
        callback({
          bool: true,
        });
        yield put({
          type: types.GET_RETIRE_AVAILABEL_INIT,
        });
      }
      yield put({ type: types.RETIRE_BALANCE_COMPLETE });
    } else {
      yield put({ type: types.RETIRE_BALANCE_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.RETIRE_BALANCE_ERROR });
  }
}

export function* retireAvailabelCall({ callback }) {
  try {
    const result = yield call(API.get, `/whitdrawal-availables`);
    if (result.status >= 200 && result.status <= 299) {
      if (
        Array.isArray(result.data.transactions) &&
        result.data.transactions.length
      ) {
        yield put({
          type: types.GET_RETIRE_AVAILABEL_COMPLETE,
          payload: result.data.transactions,
        });
        // yield put({
        //   type: types.GET_RETIRE_AVAILABEL_INIT,
        // });
        callback({ bool: true });
      } else {
        yield put({ type: types.GET_RETIRE_AVAILABEL_ERROR });
      }
    } else {
      yield put({ type: types.GET_RETIRE_AVAILABEL_ERROR });
    }
  } catch (error) {
    if (error.response === undefined) {
      callback({
        bool: false,
        message: "Por favor verifique su conexión a internet.",
      });
    } else if (error && error.response.status === 500) {
      callback({
        bool: false,
        message: "Disculpe ha ocurrido un error interno*",
      });
    } else {
      callback({
        bool: false,
        message: Object.values(error.response.data.errors)[0],
      });
    }
    yield put({ type: types.GET_RETIRE_AVAILABEL_ERROR });
  }
}

export default function* general() {
  yield takeLatest(types.POST_CONTACT_INIT, contactCall);
  yield takeLatest(types.POST1_CONTACT_INIT, contact1Call);
  yield takeLatest(types.GET_DOCUMENT_INIT, documentCall);
  yield takeLatest(types.GET_REFERRED_INIT, referralsCall);
  yield takeLatest(types.GET_REFERRED_LEVEL_INIT, referralMyLevelCall);
  yield takeLatest(types.GET_REQUIREMENTS_LEVEL_INIT, requirementsLevelCall);
  yield takeLatest(types.GET_MOVEMENTS_INIT, movementsCall);
  yield takeLatest(types.GET_OPERATIONS_INIT, operationsCall);
  yield takeLatest(types.DEPOSIT_COIN_BASE_INIT, depositCoinBaseCall);
  yield takeLatest(types.RETIRE_BALANCE_INIT, retireBalanceCall);
  yield takeLatest(types.GET_RETIRE_AVAILABEL_INIT, retireAvailabelCall);
}
