import React from "react";
import styled from "styled-components";

const Input = ({
  placeholder,
  type,
  fullWidth,
  lavel,
  marginTop,
  marginBottom,
  id,
  value,
  onChange,
  bacgkroundColor,
}) => {
  return (
    <Container
      fullWidth={fullWidth}
      marginTop={marginTop}
      marginBottom={marginBottom}
      bacgkroundColor={bacgkroundColor}
    >
      {lavel ? <Label>{placeholder}</Label> : null}
      <InputStyles
        id={id}
        value={value}
        onChange={onChange}
        type={type}
        bacgkroundColor={bacgkroundColor}
        placeholder={lavel ? null : placeholder}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => (props.fullWidth ? "100%" : "270px")};
  margin-top: ${(props) => props.marginTop};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  @media (max-width: 420px) {
    width: 100%;
    max-width: 95vw;
  }
`;
const Label = styled.label`
  display: block;
  margin-bottom: 3px;
  font-size: 14px;
  color: #728096 !important;
  font-weight: 400 !important;
`;
const InputStyles = styled.input`
  width: 100%;
  height: 37px;
  display: block;
  padding: 0px;
  padding-left: 10px;

  font-size: 14px;
  line-height: 1.6;
  background-color: ${(props) => (props.bacgkroundColor ? props.bacgkroundColor : "#fff")};
  background-clip: padding-box;
  border: 1px solid #c8cfd8;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 5px;
  outline: 0;
  color: #424e79;
  opacity: 1;
  :focus {
    outline: none !important;
    border-color: #3f768a;
  }
  ::placeholder {
    padding-left: 5px;
    color: #728096 !important;
    font-weight: 400 !important;
  }
`;
export default Input;
