/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import LandingPage from "./LandingPage";
import TermsAndConditions from "./TermsAndConditions";
import Cookies from "./Cookies";
import Privacy from "./Privacy";
import Maintaining from "./Maintaining";

const Routes = (props) => {
  const { isAuthenticated } = props;
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/mantaining" component={Maintaining} />

        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Route exact path="/cookies" component={Cookies} />
        <Route exact path="/privacy" component={Privacy} />
      </Switch>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.accessStore.token,
  };
};

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  const token = !!isAuthenticated;
  return (
    <Route
      {...rest}
      render={(props) => {
        if (token) return <Component {...props} />;
        else return <Redirect exact to="/login" />;
      }}
    />
  );
};

export default connect(mapStateToProps, {})(Routes);
