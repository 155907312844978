// import moment from "moment";
import * as types from "./types";

const initialState = {};

export default function profileStore(state = initialState, action) {
  switch (action.type) {
    case types.PUT_PROFILE_INIT:
      return {
        ...state,
        isLoggingIn: true,
      };
    case types.PUT_PROFILE_COMPLETE:
      return {
        ...state,
        isLoggingIn: false,
        profile: action.payload,
      };
    case types.PUT_PROFILE_ERROR:
      return {
        ...state,
        isLoggingIn: false,
      };
    case types.PUT_PASSWORD_INIT:
      return {
        ...state,
        isPutPassword: true,
      };
    case types.PUT_PASSWORD_COMPLETE:
      return {
        ...state,
        isPutPassword: false,
      };
    case types.PUT_PASSWORD_ERROR:
      return {
        ...state,
        isPutPassword: false,
      };
    default:
      return state;
  }
}
