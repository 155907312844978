/* eslint-disable import/no-cycle */
import { all } from "redux-saga/effects";
import access from "./access/sagas";
import profile from "./profile/sagas";
import general from "./general/sagas";
import graphics from "./graphics/sagas";

export default function* rootSaga() {
  yield all([access(), profile(), general(), graphics()]);
}
